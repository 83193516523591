import { DeleteOutlined } from '@ant-design/icons'
import { Table, Modal, Popover, Button } from 'antd'
import { BookingRole } from 'domain/booking'
import { StaffInfo } from 'domain/users'
import EditableBookingRolesList from 'presentation/components/EditableBookingRolesList'
import { useUsersViewModel } from 'presentation/scenes/users/view-models'
import React, { useState } from 'react'
import { moderatorsTableDataCyKeys } from 'shared'

export const ModeratorsTable = () => {
  const {
    staff,
    moderators,
    bookingRoles,
    actions: {
      removeBookingAdmin,
      removeAllModeratorRoles,
      createBookingRole,
      addBookingRoleForModerator,
      removeBookingRoleForModerator,
    },
  } = useUsersViewModel()

  function createBookingRoleHandler(value: string, e: any) {
    e.preventDefault()
    createBookingRole({ name: value })
  }

  function changeRoleHandler(e: any, ownerId: string, role: BookingRole) {
    if (e.target.checked) {
      addBookingRoleForModerator(ownerId, role.id)
    } else {
      removeBookingRoleForModerator(ownerId, role.id)
    }
  }

  function getBookingAdmin(record: StaffInfo) {
    return moderators.find(value => {
      return value.tsuAccountProfile.accountId === record.accountId
    })
  }

  const [userId, setUserId] = useState<string>('')
  function showModal(userId: string) {
    setUserId(userId)
  }

  function handleCancel() {
    setUserId('')
  }
  const [isDeleteAllRolesPopoverOpen, setIsDeleteAllRolesPopoverOpen] = useState<boolean>(false)

  return (
    <Table
      dataSource={staff.filter(staff =>
        moderators.find(moderator => {
          return moderator.tsuAccountProfile.accountId === staff.accountId
        })
      )}
      rowKey={record => record.id}
      data-cy={moderatorsTableDataCyKeys.table}
    >
      <Table.Column
        title='ФИО'
        dataIndex='name'
        key='name'
      />
      <Table.Column
        title='Подразделение'
        dataIndex='department'
        key='department'
        render={(text, record: StaffInfo) => record.department.name}
      />
      <Table.Column
        title='Роли'
        dataIndex='bookingRoles'
        key='bookingRoles'
        render={(text, record: StaffInfo) => {
          return (
            <>
              <Modal
                key={record.id}
                title={record.name}
                open={userId === getBookingAdmin(record)?.id!}
                onCancel={() => handleCancel()}
                width={700}
                data-cy={moderatorsTableDataCyKeys.editRolesModal.modal}
                footer={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Popover
                      title='Удалить все роли?'
                      placement='right'
                      trigger='click'
                      open={isDeleteAllRolesPopoverOpen}
                      onOpenChange={visible => setIsDeleteAllRolesPopoverOpen(visible)}
                      content={
                        <div className='delete-all-roles-popover-wrapper'>
                          <Button
                            type='primary'
                            onClick={() => setIsDeleteAllRolesPopoverOpen(false)}
                          >
                            Нет
                          </Button>

                          <Button
                            danger
                            onClick={() => {
                              removeAllModeratorRoles(getBookingAdmin(record)?.id!)
                              setIsDeleteAllRolesPopoverOpen(false)
                            }}
                            data-cy={moderatorsTableDataCyKeys.editRolesModal.deleteAllRolesButton}
                          >
                            Да
                          </Button>
                        </div>
                      }
                    >
                      <Button
                        danger
                        onClick={() => setIsDeleteAllRolesPopoverOpen(true)}
                        data-cy={moderatorsTableDataCyKeys.editRolesModal.deleteAllRolesPopover}
                      >
                        Удалить все роли
                      </Button>
                    </Popover>
                    <Button onClick={() => handleCancel()}>Закрыть окно</Button>
                  </div>
                }
              >
                <EditableBookingRolesList
                  selectedBookingRoles={getBookingAdmin(record)?.bookingRoles!}
                  bookingRoles={bookingRoles}
                  ownerId={getBookingAdmin(record)?.id!}
                  createBookingRole={createBookingRoleHandler.bind(this)}
                  changeRoleHandler={changeRoleHandler.bind(this)}
                  isMinRoleRequired={false}
                />
              </Modal>

              <Button
                type='primary'
                block
                size='small'
                onClick={() => showModal(getBookingAdmin(record)?.id!)}
                data-cy={moderatorsTableDataCyKeys.rolesButton}
              >
                Редактировать роли
              </Button>
              <div className='roles-list-wrapper'>
                {getBookingAdmin(record)?.bookingRoles.map(role => {
                  return (
                    <div
                      className='role-list-item'
                      key={role.id}
                    >
                      {role.name}
                    </div>
                  )
                })}
              </div>
            </>
          )
        }}
      />
      <Table.Column
        dataIndex='actions'
        key='actions'
        render={(text, record: StaffInfo) => (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeBookingAdmin(record.accountId)}
            data-cy={moderatorsTableDataCyKeys.deleteButton}
          />
        )}
      />
    </Table>
  )
}
