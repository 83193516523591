import { useContext } from 'react'
import { SettingsViewModelContext } from './provider'

export const useSettingsViewModel = () => {
  const value = useContext(SettingsViewModelContext)

  if (!value) {
    throw new Error('useSettingsViewModel must be used within a SettingsViewModelContext')
  }

  return value
}
