import { Button, Checkbox, Col, Form, FormInstance, Input, List, Row, Space, Typography } from 'antd'
import { BookingAudienceGroup, BookingRole } from 'domain/booking'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import './index.scss'
import { useBookingRoles } from './hooks/useBookingRoles'
import { useGroupsViewModel } from 'presentation/scenes/booking/audienceGroups/view-models'
import { useState } from 'react'

const { Text } = Typography
interface RolesListProps {
  groupForm: FormInstance<BookingAudienceGroup>
}

const NewRoleForm = () => {
  const {
    actions: { createBookingRole },
  } = useGroupsViewModel()

  const [roleName, setRoleName] = useState<string>('')
  return (
    <div className='role-form'>
      <Input
        size='small'
        defaultValue={roleName}
        onChange={e => {
          e.stopPropagation()
          setRoleName(e.currentTarget.value)
        }}
      />

      <Button
        type='primary'
        size='small'
        block
        onClick={() => {
          createBookingRole(roleName)
          setRoleName('')
        }}
      >
        Добавить роль
      </Button>
    </div>
  )
}

export const RolesList = ({ groupForm }: RolesListProps) => {
  const { state } = useGroupsViewModel()
  const requiredRoles = Form.useWatch('requiredRoles', groupForm)

  const isRoleChecked = (roleId: string) => {
    return requiredRoles.some(role => role.id === roleId)
  }

  const switchRole = (role: BookingRole) => {
    if (isRoleChecked(role.id)) {
      groupForm.setFieldValue(
        'requiredRoles',
        requiredRoles.filter(selectedRole => selectedRole.id !== role.id)
      )
    } else {
      groupForm.setFieldValue('requiredRoles', [...requiredRoles, role])
    }
  }

  const { bookingRoles, searchRoles } = useBookingRoles()

  const isDisabled = (role: BookingRole) => {
    return state.selectedGroup === undefined ? false : isRoleChecked(role.id) && requiredRoles.length === 1
  }

  return (
    <Row gutter={16}>
      <Col
        xs={24}
        md={10}
      >
        <Form.Item<BookingAudienceGroup>
          name='requiredRoles'
          valuePropName='dataSource'
        >
          <List<BookingRole>
            size='small'
            rootClassName='selected-roles-list'
            header={<Text>Список ролей: </Text>}
            footer={<NewRoleForm />}
            renderItem={role => {
              return (
                <List.Item data-cy={`rolesList-item-${role.id}`}>
                  <Text>{role.name}</Text>

                  <Button
                    type='text'
                    danger
                    icon={<CloseCircleOutlined />}
                    size='small'
                    title={isDisabled(role) ? 'Группа аудиторий должна содержать как минимум одну роль' : undefined}
                    disabled={isDisabled(role)}
                    onClick={() => switchRole(role)}
                  />
                </List.Item>
              )
            }}
          />
        </Form.Item>
      </Col>
      <Col
        xs={24}
        md={14}
      >
        <Space
          direction='vertical'
          style={{ display: 'flex' }}
        >
          <Input
            placeholder='Поиск'
            prefix={<SearchOutlined />}
            onChange={e => searchRoles(e.currentTarget.value)}
          />
          <List
            size='small'
            rootClassName='all-roles-list'
            dataSource={bookingRoles}
            renderItem={role => {
              return (
                <List.Item
                  title={isDisabled(role) ? 'Группа аудиторий должна содержать как минимум одну роль' : undefined}
                  data-cy={`allRolesList-item-${role.id}`}
                >
                  <Checkbox
                    checked={isRoleChecked(role.id)}
                    disabled={isDisabled(role)}
                    onChange={() => switchRole(role)}
                    className='checkbox-label'
                  >
                    <Text>{role.name}</Text>
                  </Checkbox>
                </List.Item>
              )
            }}
          />
        </Space>
      </Col>
    </Row>
  )
}
