import { Alert, Button, Input, Modal } from 'antd'
import { BookingApplicationStatus } from 'domain/booking'
import { useBookingApplicationViewModel } from 'presentation/scenes/booking/applications/view-models'
import React, { useState } from 'react'

export const ApplicationInfoFooter = () => {
  const {
    selectedApplication,
    pagination,
    actions: { isApplicationApprovedByRoleOfUser, approveBooking, rejectBooking },
  } = useBookingApplicationViewModel()
  const isApplicationApproved = isApplicationApprovedByRoleOfUser()

  const [isRejectModalShown, setIsRejectModalShown] = useState<boolean>(false)
  const [cancelApplicationReasonText, setCancelApplicationReasonText] = useState<string>('')

  const openModal = () => {
    setIsRejectModalShown(true)
    setCancelApplicationReasonText('')
  }

  if (
    !selectedApplication ||
    isApplicationApproved === 'INVALID_REQUIRED_ROLES' ||
    [BookingApplicationStatus.canceled, BookingApplicationStatus.rejected].includes(selectedApplication.status)
  ) {
    return null
  }

  return (
    <div className='actions-container'>
      {isApplicationApproved === 'NOT_APPROVED' && (
        <Button
          data-cy='approve-btn'
          type='primary'
          onClick={() => {
            approveBooking(pagination)
          }}
        >
          Одобрить
        </Button>
      )}

      <Button
        data-cy='reject-btn'
        danger
        onClick={() => openModal()}
      >
        Отклонить
      </Button>

      <Modal
        data-cy='modal-window'
        title='Отклонить заявку'
        open={isRejectModalShown}
        okButtonProps={{
          danger: true,
          disabled: cancelApplicationReasonText.length === 0,
        }}
        okText='Отклонить'
        onCancel={() => setIsRejectModalShown(false)}
        onOk={() => {
          rejectBooking(cancelApplicationReasonText, pagination)
          setIsRejectModalShown(false)
        }}
      >
        <Alert
          style={{ textAlign: 'center', marginBottom: '16px' }}
          description='Данное действие отменяет заявку независимо от наличия одобрения от других отделов и не может быть отменено.'
          type='error'
        />

        <Input.TextArea
          value={cancelApplicationReasonText}
          onChange={event => {
            setCancelApplicationReasonText(event.target.value)
          }}
          rows={4}
          placeholder='Укажите причину'
        />
      </Modal>
    </div>
  )
}
