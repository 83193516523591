import { BookingRoleUseCase, BookingRoleBody } from 'domain/booking'
import { RolesViewState, reducer } from './reducer'
import { useEffect, useReducer } from 'react'
import { Notifications } from 'shared'

const initState: RolesViewState = {
  bookingRoles: [],
  filteredBookingRoles: [],
  isLoading: false,
  userEmail: '',
}

export const useRolesViewModel = (bookingUseCase: BookingRoleUseCase) => {
  const [state, dispatch] = useReducer(reducer, initState)

  const onDidStartRequest: () => void = () => {
    dispatch({ type: 'DID_START_REQUEST', payload: true })
  }
  const onDidFinishRequest: () => void = () => {
    dispatch({ type: 'DID_FINISH_REQUEST', payload: false })
  }
  const onDidReceiveError: (error: Error) => void = error => {
    Notifications.handle(error)
    dispatch({ type: 'DID_RECIEVE_ERROR', payload: false })
  }

  const loadData = async () => {
    onDidStartRequest()
    try {
      let userEmail = (await bookingUseCase.getCurrentUser()).email
      let bookingRoles = await bookingUseCase.getBookingRoles()
      dispatch({
        type: 'LOAD_DATA',
        payload: {
          userEmail,
          bookingRoles,
        },
      })
      dispatch({
        type: 'SET_FILTERED_BOOKING_ROLES',
        payload: {
          filteredBookingRoles: bookingRoles,
        },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createBookingRole = async (newBookingRole: BookingRoleBody) => {
    onDidStartRequest()
    try {
      await bookingUseCase.createBookingRole(newBookingRole)
      let bookingRoles = await bookingUseCase.getBookingRoles()
      dispatch({
        type: 'SET_BOOKING_ROLES',
        payload: { bookingRoles },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const deleteBookingRole = async (bookingRoleId: string) => {
    onDidStartRequest()
    try {
      await bookingUseCase.deleteBookingRole(bookingRoleId)
      let bookingRoles = await bookingUseCase.getBookingRoles()
      dispatch({
        type: 'SET_BOOKING_ROLES',
        payload: { bookingRoles },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const updateBookingRole = async (roleId: string, newBookingRole: BookingRoleBody) => {
    onDidStartRequest()
    try {
      await bookingUseCase.updateBookingRole(roleId, newBookingRole)
      let bookingRoles = await bookingUseCase.getBookingRoles()
      dispatch({
        type: 'SET_BOOKING_ROLES',
        payload: { bookingRoles },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const setFilteredBookingRoles = (searchString: string) => {
    const filteredBookingRoles = state.bookingRoles.filter(role => {
      return role.name.toLowerCase().includes(searchString.trim().toLowerCase())
    })

    dispatch({ type: 'SET_FILTERED_BOOKING_ROLES', payload: { filteredBookingRoles } })
  }

  return {
    ...state,
    actions: {
      createBookingRole,
      deleteBookingRole,
      updateBookingRole,
      setFilteredBookingRoles,
    },
  }
}
