import { Dependencies } from './Dependencies'
import { BookingRoleUseCase, BookingAudienceGroupUseCase, BookingUseCase } from 'domain/booking'
import { LoginUseCase, LogoutUseCase } from 'domain/auth'
import { UsersUseCase } from 'domain/users'
import { CacheUseCase } from 'domain/cache'

export class UseCaseFactory {
  createLoginUseCase(): LoginUseCase {
    return new LoginUseCase(Dependencies.authRepository)
  }

  createLogoutUseCase(): LogoutUseCase {
    return new LogoutUseCase(Dependencies.authRepository)
  }

  createUsersUseCase(): UsersUseCase {
    return new UsersUseCase(Dependencies.staffRepository)
  }

  createBookingUseCase(): BookingUseCase {
    return new BookingUseCase(Dependencies.bookingRepository)
  }

  createBookingAudienceGroupUseCase(): BookingAudienceGroupUseCase {
    return new BookingAudienceGroupUseCase(Dependencies.bookingRepository, Dependencies.buildingsRepository)
  }

  createBookingRoleUseCase(): BookingRoleUseCase {
    return new BookingRoleUseCase(Dependencies.bookingRepository)
  }

  createCacheUseCase(): CacheUseCase {
    return new CacheUseCase(Dependencies.cacheRepository)
  }
}
