import { Popover, Space, Button } from 'antd'
import { useGroupsViewModel } from 'presentation/scenes/booking/audienceGroups/view-models'
import React, { useState } from 'react'
interface DeleteGroupPopoverProps {
  groupId: string
}
export const DeleteGroupPopover = ({ groupId }: DeleteGroupPopoverProps) => {
  const {
    actions: { deleteBookingAudienceGroup },
  } = useGroupsViewModel()

  const [open, setOpen] = useState(false)
  return (
    <Popover
      placement='left'
      trigger='click'
      title='Удалить группу?'
      open={open}
      onOpenChange={setOpen}
      content={
        <Space data-cy='deleteGroupPopover'>
          <Button
            danger
            onClick={() => deleteBookingAudienceGroup(groupId)}
          >
            Да
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setOpen(false)
            }}
          >
            Нет
          </Button>
        </Space>
      }
    >
      <Button danger>Удалить группу</Button>
    </Popover>
  )
}
