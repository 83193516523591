import { Notifications } from 'shared'
import { BookingUseCase } from 'domain/booking'
import { useEffect, useReducer } from 'react'
import { BookingApplicationsViewState, reducer } from './reducer'

const initState: BookingApplicationsViewState = {
  isLoading: false,
  applications: [],
  selectedApplication: undefined,
  currentUser: undefined,
  pagination: {},
  currentPage: 0,
  totalItems: 0,
}

export const useBookingApplicationsViewModel = (bookingUseCase: BookingUseCase) => {
  const [state, dispatch] = useReducer(reducer, initState)

  const onDidStartRequest: () => void = () => {
    dispatch({ type: 'DID_START_REQUEST', payload: true })
  }
  const onDidFinishRequest: () => void = () => {
    dispatch({ type: 'DID_FINISH_REQUEST', payload: false })
  }
  const onDidReceiveError: (error: Error) => void = error => {
    Notifications.handle(error)
    dispatch({ type: 'DID_RECIEVE_ERROR', payload: false })
  }

  const loadData = async () => {
    onDidStartRequest()
    try {
      let currentUser = await bookingUseCase.getCurrentUser()
      let { data: applications, currentPage, totalItems } = await bookingUseCase.getBookingApplications({})
      dispatch({
        type: 'LOAD_DATA',
        payload: {
          currentUser,
          applications,
          currentPage,
          totalItems,
        },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBookingApplications = async (pagination: BookingApplicationsViewState['pagination']) => {
    onDidStartRequest()
    try {
      const {
        data: applications,
        currentPage,
        totalItems,
      } = await bookingUseCase.getBookingApplications({ ...pagination })
      dispatch({
        type: 'GET_BOOKING_APPLICATIONS',
        payload: { applications, currentPage, pagination, totalItems },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const approveBooking = async (pagination: BookingApplicationsViewState['pagination']) => {
    if (!state.selectedApplication) return

    onDidStartRequest()

    try {
      const data = await bookingUseCase.approveBooking(state.selectedApplication.id)
      const {
        data: applications,
        currentPage,
        totalItems,
      } = await bookingUseCase.getBookingApplications({ ...pagination })
      const selectedApplication = data
      dispatch({
        type: 'APPOVE_BOOKING',
        payload: { applications, selectedApplication, currentPage, totalItems },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const rejectBooking = async (reason: string, pagination: BookingApplicationsViewState['pagination']) => {
    if (!state.selectedApplication) return

    onDidStartRequest()
    try {
      const data = await bookingUseCase.rejectBooking(state.selectedApplication?.id, reason)

      const {
        data: applications,
        currentPage,
        totalItems,
      } = await bookingUseCase.getBookingApplications({ ...pagination })
      const selectedApplication = data
      dispatch({
        type: 'REJECT_BOOKING',
        payload: { applications, selectedApplication, currentPage, totalItems },
      })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const isApplicationApprovedByRoleOfUser = (): 'INVALID_REQUIRED_ROLES' | 'APPROVED' | 'NOT_APPROVED' => {
    const requiredRolesOfUser = state.currentUser?.bookingRoles?.filter(
      role => state.selectedApplication?.requiredBookingRoles.some(applicationRole => applicationRole.id === role.id)
    )

    if (requiredRolesOfUser !== undefined && requiredRolesOfUser.length > 0) {
      const isApplicationApprovedByRoleOfUser = state.selectedApplication?.approvedByRoles.some(approvedRole =>
        requiredRolesOfUser.some(requiredRole => requiredRole.id === approvedRole.id)
      )

      return isApplicationApprovedByRoleOfUser ? 'APPROVED' : 'NOT_APPROVED'
    } else {
      return 'INVALID_REQUIRED_ROLES'
    }
  }

  return {
    ...state,
    actions: {
      getBookingApplications,
      approveBooking,
      rejectBooking,
      isApplicationApprovedByRoleOfUser,
      dispatch,
    },
  }
}
