import { useCallback } from 'react'
import { Card, Input, Spin, Typography } from 'antd'
import './booking-applications.scss'
import moment from 'moment'
import 'moment/locale/ru'
import { useBookingApplicationViewModel } from '../view-models/hook'
import { ApplicationsTable } from './components'
import { SelectedApplicationCard } from './components/SelectedApplicationCard'

const { Title } = Typography
const momentLocale = 'ru'

moment.locale(momentLocale)

export const BookingApplicationsView = () => {
  const {
    isLoading,
    pagination,
    actions: { getBookingApplications },
  } = useBookingApplicationViewModel()

  const setFilteredApplications = useCallback(
    (searchString: string) => {
      getBookingApplications({ ...pagination, title: searchString, page: 1 })
    },
    [getBookingApplications, pagination]
  )
  return (
    <div className='booking-applications-page-container'>
      <Title>Заявки</Title>
      <Card
        data-cy='card-container'
        className='booking-applications-content-container'
      >
        {/* @ts-ignore */}
        <Spin
          tip='Загрузка...'
          spinning={isLoading}
        >
          <div data-cy='search-field-container'>
            <Input.Search
              className='booking-applications-search-input'
              placeholder='Название'
              onSearch={setFilteredApplications}
              enterButton
            />
          </div>
          <div className='booking-applications-master-detail-container'>
            <ApplicationsTable />
            <SelectedApplicationCard />
          </div>
        </Spin>
      </Card>
    </div>
  )
}
