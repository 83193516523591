import { Card, Table, Tag, Typography } from 'antd'
import { BookingAudienceGroup } from 'domain/booking'
import React from 'react'
import { useGroupsViewModel } from '../../../view-models'
const { Paragraph } = Typography

export const GroupsList = () => {
  const { state, setState } = useGroupsViewModel()

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Table
        showHeader={false}
        dataSource={state.bookingAudienceGroups}
        rowKey={record => record.id}
        pagination={{
          size: 'small',
        }}
        data-cy={`audienceGroups-groupsList`}
      >
        <Table.Column
          dataIndex='title'
          render={(text, record: BookingAudienceGroup) => {
            return (
              <div
                className='audience-groups-list-content'
                onClick={() => setState({ selectedGroup: record })}
                data-cy='groupsList-item'
              >
                <Paragraph
                  ellipsis={{
                    rows: 1,
                  }}
                  className='title'
                >
                  {record.name}
                </Paragraph>

                <div style={{ marginTop: '16px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  {record.requiredRoles.map(role => {
                    return <Tag key={role.id}>{role.name}</Tag>
                  })}
                </div>
              </div>
            )
          }}
        />
      </Table>
    </Card>
  )
}
