import { Button, Divider, Form, Input, Space, Switch } from 'antd'
import { useEffect } from 'react'
import { useGroupsViewModel } from '../../../view-models'
import { BookingAudienceGroup } from 'domain/booking'
import MDEditor from '@uiw/react-md-editor'
import { RolesList } from './components'
import { AudiencesList } from './components/AudiencesList'
import { AdditionalFieldsFormItem } from './components/AdditionalFieldsFormItem'
import { DeleteGroupPopover } from './components/DeleteGroupPopover'
interface GroupFormProps {}
export const GroupForm = (props: GroupFormProps) => {
  const {
    state: { selectedGroup },
    actions: { createBookingAudienceGroup, updateBookingAudienceGroup },
  } = useGroupsViewModel()

  const [groupForm] = Form.useForm<BookingAudienceGroup>()

  useEffect(() => {
    if (selectedGroup) {
      groupForm.setFieldsValue(selectedGroup)
    }
    return () => {
      groupForm.resetFields()
      groupForm.setFieldsValue({ bookingFormAdditionalFields: [] })
    }
  }, [groupForm, selectedGroup])

  const showEmailTemplate = Form.useWatch('isNotifyAboutNewBookingsRequested', groupForm)
  const groupId = Form.useWatch('id', groupForm)
  const groupName = Form.useWatch('name', groupForm)

  return (
    <Form
      form={groupForm}
      initialValues={{
        id: null,
        name: '',
        isNotifyAboutNewBookingsRequested: false,
        emailTemplate: '',
        requiredRoles: [],
        audiences: [],
        bookingFormAdditionalFields: [],
      }}
      onFinish={value => (value.id ? updateBookingAudienceGroup(value) : createBookingAudienceGroup(value))}
      data-cy='bookingGroups-form'
    >
      <Form.Item<BookingAudienceGroup> name='id'>
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          {groupId ? (
            <>
              <DeleteGroupPopover groupId={groupId} />
              <Button htmlType='submit'>Применить изменения</Button>
            </>
          ) : (
            <>
              <Button
                htmlType='submit'
                disabled={groupName === ''}
              >
                Создать
              </Button>
            </>
          )}
        </Space>
      </Form.Item>

      <Form.Item<BookingAudienceGroup> name='name'>
        <Input />
      </Form.Item>
      <Form.Item<BookingAudienceGroup>
        name='isNotifyAboutNewBookingsRequested'
        label='Отправлять письмо при создании заявки'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      {showEmailTemplate && (
        <Form.Item<BookingAudienceGroup>
          name='emailTemplate'
          valuePropName='value'
        >
          <MDEditor data-color-mode='light' />
        </Form.Item>
      )}

      <Divider />
      <RolesList groupForm={groupForm} />
      <Divider />
      <AudiencesList groupForm={groupForm} />
      <Divider />
      <AdditionalFieldsFormItem groupForm={groupForm} />
    </Form>
  )
}
