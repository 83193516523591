import { Input, Popover, Button } from 'antd'
import React, { useState } from 'react'
import { useRolesViewModel } from '../../../view-models'
import { NewBookingRoleForm } from './components/NewBookingRoleForm'

export const RolesHeader = () => {
  const {
    actions: { setFilteredBookingRoles },
  } = useRolesViewModel()

  const [isAddPopoverVisible, setIsAddPopoverVisible] = useState<boolean>(false)

  return (
    <div
      className='header-wrapper'
      data-cy='header-role'
    >
      <Input.Search
        className='search-input'
        placeholder='Название'
        onSearch={setFilteredBookingRoles}
        enterButton
      />

      <Popover
        placement='left'
        trigger='click'
        open={isAddPopoverVisible}
        onOpenChange={setIsAddPopoverVisible}
        content={<NewBookingRoleForm />}
      >
        <Button
          type='primary'
          data-cy='add-new-btn-role'
          onClick={() => setIsAddPopoverVisible(true)}
        >
          Добавить роль
        </Button>
      </Popover>
    </div>
  )
}
