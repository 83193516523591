import { Form, Typography } from 'antd'
import { BookingRole } from 'domain/booking'
import React from 'react'
interface EditableParagraphsProps {
  selectedRole: BookingRole
  setSelectedRole: React.Dispatch<
    React.SetStateAction<
      | {
          selectedRole: BookingRole
          initialSelectedRole: BookingRole
        }
      | undefined
    >
  >
}
export const EditableParagraphs = ({ selectedRole, setSelectedRole }: EditableParagraphsProps) => {
  return (
    <>
      <Form.Item
        validateStatus={selectedRole.name === '' ? 'error' : 'validating'}
        help={selectedRole.name === '' ? 'Введите название роли' : ''}
      >
        <Typography.Title
          data-cy='name-role'
          style={{ margin: 0, marginRight: '8px' }}
          editable={{
            text: selectedRole.name,
            onChange: name =>
              setSelectedRole(val => {
                if (!val) return val

                return {
                  ...val,
                  selectedRole: {
                    ...val.selectedRole,
                    name,
                  },
                }
              }),
          }}
        >
          {selectedRole.name}
        </Typography.Title>
      </Form.Item>

      <div className='email-wrapper'>
        <Typography.Title
          level={5}
          style={{ marginRight: '8px' }}
        >
          Почта:{' '}
        </Typography.Title>

        <Form.Item
          validateStatus={selectedRole.email === '' ? 'error' : 'validating'}
          help={selectedRole.email === '' ? 'Введите почту' : ''}
        >
          <Typography.Title
            data-cy='email-role'
            level={5}
            editable={{
              onChange: email =>
                setSelectedRole(val => {
                  if (!val) return val

                  return {
                    ...val,
                    selectedRole: {
                      ...val.selectedRole,
                      email,
                    },
                  }
                }),
            }}
          >
            {selectedRole.email}
          </Typography.Title>
        </Form.Item>
      </div>
    </>
  )
}
