import { PropsWithChildren, createContext } from 'react'
import { UseCaseFactory } from 'shared'
import { useGroupsViewModel } from './AudienceGroupsViewModel'

export const AudienceGroupsViewModelContext = createContext<ReturnType<typeof useGroupsViewModel> | undefined>(
  undefined
)

export const GroupsViewModelProvider = ({ children }: PropsWithChildren) => {
  const useCaseFactory = new UseCaseFactory()
  let bookingRolesViewModel = useGroupsViewModel(
    useCaseFactory.createBookingAudienceGroupUseCase(),
    useCaseFactory.createBookingRoleUseCase()
  )

  return (
    <AudienceGroupsViewModelContext.Provider value={bookingRolesViewModel}>
      {children}
    </AudienceGroupsViewModelContext.Provider>
  )
}
