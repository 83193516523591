import { BookingApplication, BookingApplicationStatus } from 'domain/booking'
import { UserProfile } from 'domain/common'
import { Action } from './actions'

export interface BookingApplicationsViewState {
  applications: BookingApplication[]
  isLoading: boolean
  selectedApplication?: BookingApplication
  currentUser?: UserProfile
  pagination: {
    page?: number
    limit?: number
    sortOrder?: 'DEFAULT' | 'ASC' | 'DESC'
    title?: string
    statuses?: Array<BookingApplicationStatus>
  }
  totalItems: number
  currentPage: number
}

export const reducer = (
  state: BookingApplicationsViewState,
  { type, payload }: Action
): BookingApplicationsViewState => {
  switch (type) {
    case 'DID_START_REQUEST':
      return { ...state, isLoading: payload }
    case 'DID_FINISH_REQUEST':
      return { ...state, isLoading: payload }
    case 'DID_RECIEVE_ERROR':
      return { ...state, isLoading: payload }
    case 'LOAD_DATA': {
      return { ...state, ...payload }
    }
    case 'GET_BOOKING_APPLICATIONS': {
      return { ...state, ...payload }
    }
    case 'SET_SELECTED_APPLICATION': {
      const { selectedApplication } = payload
      return { ...state, selectedApplication }
    }
    case 'APPOVE_BOOKING': {
      return { ...state, ...payload }
    }
    case 'REJECT_BOOKING': {
      return { ...state, ...payload }
    }
    default:
      return state
  }
}
