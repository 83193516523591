import { useState } from 'react'
import { AutoComplete, Card, Typography, Spin } from 'antd'
import './users-view.scss'
import { useUsersViewModel } from '../view-models'
import { AddNewModeratorPopover, ModeratorsTable } from './components'
import { searchInputDataCy, searchInputItemDataCy } from 'shared'

const { Title } = Typography

export const UsersView = () => {
  const { isLoading, staff } = useUsersViewModel()

  const [selectedUserId, setSelectedUser] = useState<string>('')

  return (
    <div className='users-page-container'>
      <Title>Модераторы</Title>
      <Spin
        tip='Загрузка...'
        spinning={isLoading}
      >
        <Card className='users-content-container'>
          <div className='search-container'>
            <AutoComplete
              className='search-input'
              filterOption
              placeholder='Поиск по ФИО сотрудника'
              data-cy={searchInputDataCy}
              onSelect={(value, option) => {
                setSelectedUser(option.key)
              }}
            >
              {staff.map(item => {
                return (
                  <AutoComplete.Option
                    key={item.accountId}
                    value={item.name}
                    data-cy={searchInputItemDataCy}
                  >
                    {item.name}
                  </AutoComplete.Option>
                )
              })}
            </AutoComplete>
            <AddNewModeratorPopover selectedUserId={selectedUserId} />
          </div>
          <ModeratorsTable />
        </Card>
      </Spin>
    </div>
  )
}
