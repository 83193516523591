import { useContext } from 'react'
import { RolesViewModelContext } from './provider'

export const useRolesViewModel = () => {
  const value = useContext(RolesViewModelContext)

  if (!value) {
    throw new Error('useRolesViewModel must be used within a RolesViewModelContext')
  }

  return value
}
