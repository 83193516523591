import { BookingAudienceGroup, BookingRole } from 'domain/booking'
import { Building, Audience } from 'domain/buildings'
import { UserProfile } from 'domain/common'

export interface AudienceGroupsViewState {
  bookingAudienceGroups: BookingAudienceGroup[]
  selectedGroup?: BookingAudienceGroup
  buildings: Building[]
  audiences: Audience[]
  bookingRoles: BookingRole[]
  formFieldTypes: string[]
  isLoading: boolean
  bookingFormFieldsTypes: string[]
  userProfile?: UserProfile
}

export const reducer = (state: AudienceGroupsViewState, action: Partial<AudienceGroupsViewState>) => {
  return { ...state, ...action }
}
