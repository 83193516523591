import { UserProfile, UserRole } from 'domain/common'
import { StaffInfo } from '../models'
import { StaffRepository } from '../repository'
import { EmptyResponse } from 'data'

export class UsersUseCase {
  private staffRepository: StaffRepository

  constructor(staffRepository: StaffRepository) {
    this.staffRepository = staffRepository
  }

  getStaff(): Promise<StaffInfo[]> {
    return this.staffRepository.getStaff()
  }

  addBookingAdmin(accountId: string, bookingRoleIds: string[]): Promise<EmptyResponse> {
    return this.staffRepository.addBookingAdmin(accountId, bookingRoleIds)
  }

  removeBookingAdmin(accountId: string): Promise<EmptyResponse> {
    return this.staffRepository.removeBookingAdmin(accountId)
  }

  getModerators(): Promise<UserProfile[]> {
    return this.staffRepository.getModerators()
  }

  addBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
    return this.staffRepository.addBookingRoleForModerator(moderatorId, bookingRoleId)
  }

  removeAllModeratorRoles(moderatorId: string): Promise<EmptyResponse> {
    return this.staffRepository.removeAllModeratorRoles(moderatorId)
  }

  removeBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
    return this.staffRepository.removeBookingRoleForModerator(moderatorId, bookingRoleId)
  }

  addRole(tsuAccountId: string, role: UserRole): Promise<EmptyResponse> {
    return this.staffRepository.addRole(tsuAccountId, role)
  }
}
