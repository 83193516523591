import { UserProfile } from 'domain/common'
import { StaffInfo } from 'domain/users'

export interface SettingsViewState {
  staff: StaffInfo[]
  isLoading: boolean
  currentUser?: UserProfile
}

export const reducer = (state: SettingsViewState, action: Partial<SettingsViewState>): SettingsViewState => {
  return { ...state, ...action }
}
