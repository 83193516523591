import { BookingRoleBody, BookingRoleUseCase } from 'domain/booking'
import { UsersUseCase } from 'domain/users'
import { useEffect, useReducer } from 'react'
import { Notifications } from 'shared'
import { UsersViewState, reducer } from './reducer'
import { CacheUseCase } from 'domain/cache/interactors/CacheUseCase'

const initState: UsersViewState = {
  staff: [],
  moderators: [],
  isLoading: false,
  bookingRoles: [],
  currentUser: undefined,
}

export const useUsersViewModel = (
  usersUseCase: UsersUseCase,
  bookingRoleUseCase: BookingRoleUseCase,
  cacheUseCase: CacheUseCase
) => {
  const [state, dispatch] = useReducer(reducer, initState)

  const onDidStartRequest: () => void = () => {
    dispatch({ type: 'DID_START_REQUEST', payload: true })
  }
  const onDidFinishRequest: () => void = () => {
    dispatch({ type: 'DID_FINISH_REQUEST', payload: false })
  }
  const onDidReceiveError: (error: Error) => void = error => {
    Notifications.handle(error)
    dispatch({ type: 'DID_RECIEVE_ERROR', payload: false })
  }

  const loadData = () => {
    onDidStartRequest()
    Promise.all([
      usersUseCase.getStaff(),
      bookingRoleUseCase.getBookingRoles(),
      usersUseCase.getModerators(),
      bookingRoleUseCase.getCurrentUser(),
    ])
      .then(response => {
        const staff = response[0]
        const bookingRoles = response[1]
        const moderators = response[2]
        const currentUser = response[3]
        dispatch({
          type: 'LOAD_DATA',
          payload: { staff, bookingRoles, moderators, currentUser },
        })
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addBookingAdmin = (bookingRoleIds: string[], accountId?: string) => {
    if (!accountId) return
    onDidStartRequest()

    usersUseCase
      .addBookingAdmin(accountId, bookingRoleIds)
      .then(() => {
        getModerators()
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  const removeBookingAdmin = (accountId?: string) => {
    if (!accountId) return
    onDidStartRequest()
    usersUseCase
      .removeBookingAdmin(accountId)
      .then(() => {
        getModerators()
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  const getBookingRoles = () => {
    onDidStartRequest()
    bookingRoleUseCase
      .getBookingRoles()
      .then(bookingRoles => {
        dispatch({
          type: 'SET_BOOKING_ROLES',
          payload: { bookingRoles },
        })
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  const getModerators = () => {
    onDidStartRequest()
    usersUseCase
      .getModerators()
      .then(moderators => {
        dispatch({
          type: 'SET_MODERATORS',
          payload: { moderators },
        })
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  const createBookingRole = (newBookingRole: BookingRoleBody) => {
    onDidStartRequest()
    bookingRoleUseCase
      .createBookingRole({ ...newBookingRole, email: state.currentUser?.email })
      .then(() => {
        getBookingRoles()
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  const addBookingRoleForModerator = (moderatorId: string, bookingRoleId: string) => {
    onDidStartRequest()
    usersUseCase
      .addBookingRoleForModerator(moderatorId, bookingRoleId)
      .then(() => {
        getModerators()
      })
      .catch(error => {
        onDidReceiveError(error)
      })
  }

  const removeBookingRoleForModerator = (moderatorId: string, bookingRoleId: string) => {
    onDidStartRequest()
    usersUseCase
      .removeBookingRoleForModerator(moderatorId, bookingRoleId)
      .then(() => {
        getModerators()
      })
      .catch(error => {
        onDidReceiveError(error)
      })
  }

  const removeAllModeratorRoles = (moderatorId: string) => {
    onDidStartRequest()
    usersUseCase
      .removeAllModeratorRoles(moderatorId)
      .then(() => {
        getModerators()
      })
      .catch(error => {
        onDidReceiveError(error)
      })
  }

  return {
    ...state,
    actions: {
      addBookingAdmin,
      removeBookingAdmin,
      createBookingRole,
      addBookingRoleForModerator,
      removeBookingRoleForModerator,
      removeAllModeratorRoles,
    },
  }
}
