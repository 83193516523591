const BOOKING = '/booking'

export const BOOKING_APPLICATIONS_ROUTE = `${BOOKING}/applications`
export const BOOKING_AUDIENCE_GROUPS_ROUTE = `${BOOKING}/audienceGroups`
export const BOOKING_ROLES_ROUTE = `${BOOKING}/roles`

export const USERS_ROUTE = '/users'
export const LOGOUT_ROUTE = '/logout'

export const LOGIN_FINISH_ROUTE = '/login/finish'

export const SETTINGS_ROUTE = '/settings'

export const ANONYMOUS_DEFAULT_ROUTE = '/'
