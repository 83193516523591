import { UserProfile, UserRole } from 'domain/common'
import { StaffRepository, StaffInfo } from 'domain/users'
import { HTTPMethod } from '../HTTPMethod'
import { NetworkService } from '../NetworkService'
import { EmptyResponse } from '../EmptyResponse'
import { CacheRequestConfig } from 'axios-cache-interceptor'

const getModeratorsDeleteCacheConfig: Partial<CacheRequestConfig> = {
  cache: {
    cacheTakeover: false,
    update: {
      'get-moderators': 'delete',
    },
  },
}

const getProfileDeleteCacheConfig: Partial<CacheRequestConfig> = {
  cache: {
    cacheTakeover: false,
    update: {
      'get-profile': 'delete',
    },
  },
}
export class StaffService extends NetworkService implements StaffRepository {
  getStaff(): Promise<StaffInfo[]> {
    return this.request('/staff', HTTPMethod.get)
  }

  addBookingAdmin(accountId: string, bookingRoleIds: string[]): Promise<EmptyResponse> {
    return this.request(
      `/staff/${accountId}/booking-admins`,
      HTTPMethod.post,
      { bookingRoles: bookingRoleIds },
      getModeratorsDeleteCacheConfig
    )
  }

  removeBookingAdmin(accountId: string): Promise<EmptyResponse> {
    return this.request(`/staff/${accountId}/booking-admins`, HTTPMethod.delete, null, getModeratorsDeleteCacheConfig)
  }

  getModerators(): Promise<UserProfile[]> {
    return this.request('/users/moderators', HTTPMethod.get, null, { id: 'get-moderators' })
  }

  addBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
    return this.request(
      `/users/moderators/${moderatorId}/booking-roles`,
      HTTPMethod.post,
      {
        bookingRoleId: bookingRoleId,
      },
      getModeratorsDeleteCacheConfig
    )
  }

  removeAllModeratorRoles(moderatorId: string): Promise<EmptyResponse> {
    return this.request(
      `/users/moderators/${moderatorId}/booking-roles`,
      HTTPMethod.delete,
      null,
      getModeratorsDeleteCacheConfig
    )
  }

  removeBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
    return this.request(
      `/users/moderators/${moderatorId}/booking-roles/${bookingRoleId}`,
      HTTPMethod.delete,
      null,
      getModeratorsDeleteCacheConfig
    )
  }

  addRole(tsuAccountId: string, role: UserRole): Promise<EmptyResponse> {
    return this.request(`/users/${tsuAccountId}/roles`, HTTPMethod.post, { role: role }, getProfileDeleteCacheConfig)
  }
}
