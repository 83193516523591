import { EmptyResponse } from 'data'
import { CacheRepository } from '../repository'

export class CacheUseCase {
  private cacheRepository: CacheRepository

  constructor(bookingRepository: CacheRepository) {
    this.cacheRepository = bookingRepository
  }

  updateCache(): Promise<EmptyResponse> {
    return this.cacheRepository.updateCache()
  }
}
