import { Building, Audience } from 'domain/buildings'

import { NetworkConstants } from 'data/common'
import { HTTPMethod } from '../HTTPMethod'
import { NetworkService } from '../NetworkService'
import { BuildingsRepository } from 'domain/buildings'

export class BuildingsService extends NetworkService implements BuildingsRepository {
  getBuildings(): Promise<Building[]> {
    // базовый URL привязан к /api/admin/v1
    return this.request(`${NetworkConstants.apiWebURL}/buildings`, HTTPMethod.get)
  }

  getAudiences(selectedBuildingId: string): Promise<Audience[]> {
    return this.request(`${NetworkConstants.apiWebURL}/buildings/${selectedBuildingId}/audiences`, HTTPMethod.get)
  }
}
