import { EmptyResponse } from 'data'
import { BookingAudienceGroup, BookingAudienceGroupBody, BookingFormFieldBody, BookingRepository } from 'domain/booking'
import { Building, Audience, BuildingsRepository } from 'domain/buildings'

export class BookingAudienceGroupUseCase {
  private bookingRepository: BookingRepository
  private buildingsRepository: BuildingsRepository

  constructor(bookingRepository: BookingRepository, buildingsRepository: BuildingsRepository) {
    this.bookingRepository = bookingRepository
    this.buildingsRepository = buildingsRepository
  }

  getBookingFormFieldsTypes(): Promise<string[]> {
    return this.bookingRepository.getBookingFormFieldsTypes()
  }

  addFormFieldInGroup(groupId: string, formField: BookingFormFieldBody): Promise<EmptyResponse> {
    return this.bookingRepository.addFormFieldInGroup(groupId, formField)
  }

  updateFormFieldInGroup(
    groupId: string,
    formFieldId: string,
    formField: BookingFormFieldBody
  ): Promise<EmptyResponse> {
    return this.bookingRepository.updateFormFieldInGroup(groupId, formFieldId, formField)
  }

  deleteFormFieldFromGroup(groupId: string, formFieldId: string): Promise<EmptyResponse> {
    return this.bookingRepository.deleteFormFieldFromGroup(groupId, formFieldId)
  }

  getBookingGroups(): Promise<BookingAudienceGroup[]> {
    return this.bookingRepository.getBookingGroups()
  }

  createBookingAudienceGroup(newBookingAudienceGroup: BookingAudienceGroupBody): Promise<BookingAudienceGroup> {
    return this.bookingRepository.createBookingAudienceGroup(newBookingAudienceGroup)
  }

  updateBookingAudienceGroup(
    groupId: string,
    newBookingAudienceGroup: BookingAudienceGroupBody
  ): Promise<EmptyResponse> {
    return this.bookingRepository.updateBookingAudienceGroup(groupId, newBookingAudienceGroup)
  }

  deleteBookingAudienceGroup(audienceGroupId: string): Promise<EmptyResponse> {
    return this.bookingRepository.deleteBookingAudienceGroup(audienceGroupId)
  }

  getBuildings(): Promise<Building[]> {
    return this.buildingsRepository.getBuildings()
  }

  getAudiences(buildingId: string): Promise<Audience[]> {
    return this.buildingsRepository.getAudiences(buildingId)
  }

  addAudienceInGroup(groupId: string, audienceId: string): Promise<EmptyResponse> {
    return this.bookingRepository.addAudienceInGroup(groupId, audienceId)
  }

  deleteAudienceFromGroup(groupId: string, audienceId: string): Promise<EmptyResponse> {
    return this.bookingRepository.deleteAudienceFromGroup(groupId, audienceId)
  }

  addRoleInGroup(groupId: string, roleId: string): Promise<EmptyResponse> {
    return this.bookingRepository.addRoleInGroup(groupId, roleId)
  }

  deleteRoleFromGroup(groupId: string, roleId: string): Promise<EmptyResponse> {
    return this.bookingRepository.deleteRoleFromGroup(groupId, roleId)
  }
}
