import { BookingApplicationStatus } from 'domain/booking'

interface StatusInfo {
  name: string
  color: string
}

export const statusInfoRecord: Record<BookingApplicationStatus, StatusInfo> = {
  [BookingApplicationStatus.new]: { name: 'Новый', color: 'blue' },
  [BookingApplicationStatus.approved]: { name: 'Одобрено', color: 'green' },
  [BookingApplicationStatus.rejected]: { name: 'Отклонено', color: 'red' },
  [BookingApplicationStatus.canceled]: { name: 'Отменено', color: 'volcano' },
  [BookingApplicationStatus.requireApprove]: { name: 'Требуется согласование', color: 'orange' },
}
