import { Card, Col, Row, Typography, Spin } from 'antd'

import './audience-groups.scss'
import './selected-group.scss'
import { useGroupsViewModel } from '../view-models'
import { ViewHeader } from './components/ViewHeader'
import { GroupsList } from './components/GroupsList'
import { GroupForm } from './components/GroupForm'

const { Title } = Typography

export const AudienceGroupsView = () => {
  const { state } = useGroupsViewModel()

  return (
    <div className='audience-groups-page-container'>
      <Title>Группы аудиторий</Title>

      <Card>
        <Spin
          tip='Загрузка...'
          spinning={state.isLoading}
        >
          <ViewHeader />

          <Row
            gutter={16}
            className='audience-groups-content-container'
          >
            <Col
              xs={24}
              md={7}
            >
              <GroupsList />
            </Col>
            <Col
              xs={24}
              md={17}
            >
              <Card>
                <GroupForm />
              </Card>
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  )
}
