import { UserProfile } from 'domain/common'
import { DataStore } from './DataStore'

enum DataStoreKey {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  userProfile = 'userProfile',
}

export class LocalDataStore implements DataStore {
  // Access token

  get accessToken(): string | null {
    return localStorage.getItem(DataStoreKey.accessToken)
  }
  set accessToken(value: string | null) {
    if (value) {
      localStorage.setItem(DataStoreKey.accessToken, value)
    } else {
      localStorage.removeItem(DataStoreKey.accessToken)
    }
  }

  // Refresh token

  get refreshToken(): string | null {
    return localStorage.getItem(DataStoreKey.refreshToken)
  }
  set refreshToken(value: string | null) {
    if (value) {
      localStorage.setItem(DataStoreKey.refreshToken, value)
    } else {
      localStorage.removeItem(DataStoreKey.refreshToken)
    }
  }

  // User profile

  get userProfile(): UserProfile | null {
    let data = localStorage.getItem(DataStoreKey.userProfile)

    if (data) {
      return JSON.parse(data)
    } else {
      return null
    }
  }
  set userProfile(value: UserProfile | null) {
    if (value) {
      localStorage.setItem(DataStoreKey.userProfile, JSON.stringify(value))
    } else {
      localStorage.removeItem(DataStoreKey.userProfile)
    }
  }
}
