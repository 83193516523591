import { BookingApplicationStatus } from 'domain/booking'
import { statusInfoRecord } from '../common'

export const tableFilters = [
  {
    text: statusInfoRecord[BookingApplicationStatus.new].name,
    value: BookingApplicationStatus.new,
  },
  {
    text: statusInfoRecord[BookingApplicationStatus.approved].name,
    value: BookingApplicationStatus.approved,
  },
  {
    text: statusInfoRecord[BookingApplicationStatus.rejected].name,
    value: BookingApplicationStatus.rejected,
  },
  {
    text: statusInfoRecord[BookingApplicationStatus.canceled].name,
    value: BookingApplicationStatus.canceled,
  },
  {
    text: statusInfoRecord[BookingApplicationStatus.requireApprove].name,
    value: BookingApplicationStatus.requireApprove,
  },
]
