import { useContext } from 'react'
import { BookingApplicationsViewModelContext } from './provider'

export const useBookingApplicationViewModel = () => {
  const value = useContext(BookingApplicationsViewModelContext)

  if (!value) {
    throw new Error('useBookingApplicationViewModel must be used within a BookingApplicationViewModelContext')
  }

  return value
}
