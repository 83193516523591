import { Col, Row, Spin, Empty } from 'antd'
import Card from 'antd/lib/card/Card'
import Title from 'antd/lib/typography/Title'
import { useState } from 'react'
import { BookingRole } from '../../../../../domain/booking/models/BookingRole'
import './roles-view.scss'
import { useRolesViewModel } from '../view-models'
import { EditableParagraphs, RolesHeader, RolesList, SelectedRoleActions } from './components'

export const RolesView = () => {
  const { isLoading } = useRolesViewModel()

  const [selectedAndInitialRole, setSelectedRole] = useState<
    | {
        selectedRole: BookingRole
        initialSelectedRole: BookingRole
      }
    | undefined
  >()

  const { selectedRole, initialSelectedRole } = { ...selectedAndInitialRole }

  return (
    <div className='audience-groups-page-container'>
      <Title>Роли</Title>

      <Card>
        <Spin
          tip='Загрузка...'
          spinning={isLoading}
        >
          <RolesHeader />

          <Row
            gutter={16}
            className='audience-groups-content-container'
          >
            <Col span={7}>
              <RolesList setselectedRole={setSelectedRole} />
            </Col>

            <Col span={17}>
              <Card data-cy='card-role'>
                {selectedRole ? (
                  <>
                    <SelectedRoleActions
                      selectedRole={selectedRole}
                      initialSelectedRole={initialSelectedRole}
                      setSelectedRole={setSelectedRole}
                    />
                    <EditableParagraphs
                      selectedRole={selectedRole}
                      setSelectedRole={setSelectedRole}
                    />
                  </>
                ) : (
                  <Empty />
                )}
              </Card>
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  )
}
