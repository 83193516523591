import React from 'react'
import LoginFinishViewModel, {
  LoginFinishViewModelDelegate,
} from 'presentation/scenes/autorization/login/view-models/LoginFinishViewModel'
import LoginViewModel, {
  LoginViewModelDelegate,
} from 'presentation/scenes/autorization/login/view-models/LoginViewModel'
import Login from 'presentation/scenes/autorization/login/views/Login'
import LoginFinish from 'presentation/scenes/autorization/login/views/LoginFinish'
import { BookingApplicationsViewModelProvider } from 'presentation/scenes/booking/applications/view-models'
import { BookingApplicationsView } from 'presentation/scenes/booking/applications/views/BookingApplicationsView'
import { GroupsViewModelProvider } from 'presentation/scenes/booking/audienceGroups/view-models'
import { AudienceGroupsView } from 'presentation/scenes/booking/audienceGroups/views/AudienceGroupsView'
import { RolesViewModelProvider } from 'presentation/scenes/booking/roles/view-models'
import { RolesView } from 'presentation/scenes/booking/roles/views/RolesView'
import { MainLayout } from 'presentation/scenes/main/MainLayout'
import { UsersViewModelProvider } from 'presentation/scenes/users/view-models'
import { UsersView } from 'presentation/scenes/users/views/UsersView'
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'

import Logout from '../scenes/autorization/logout/views/Logout'
import LogoutViewModel, { LogoutViewModelDelegate } from '../scenes/autorization/logout/view-models/LogoutViewModel'

import {
  ANONYMOUS_DEFAULT_ROUTE,
  BOOKING_APPLICATIONS_ROUTE,
  BOOKING_AUDIENCE_GROUPS_ROUTE,
  BOOKING_ROLES_ROUTE,
  Dependencies,
  LOGIN_FINISH_ROUTE,
  LOGOUT_ROUTE,
  SETTINGS_ROUTE,
  USERS_ROUTE,
  UseCaseFactory,
} from 'shared'
import { SettingsView } from 'presentation/scenes/settings/view'
import { SettingsViewModelProvider } from 'presentation/scenes/settings/view-models'
import { UserRole } from 'domain/common'
import { isUserHaveRequiredRoles } from './functions/isUserHaveRequiredRoles'
import { notification } from 'antd'

interface MainRouterProps {}

interface MainRouterState {
  isAuthorized: boolean
}

export default class MainRouter
  extends React.Component<MainRouterProps, MainRouterState>
  implements LoginFinishViewModelDelegate, LoginViewModelDelegate, LogoutViewModelDelegate
{
  private useCaseFactory = new UseCaseFactory()

  constructor(props: MainRouterProps) {
    super(props)

    this.state = {
      isAuthorized: false,
    }
  }

  componentDidMount() {
    if (Dependencies.dataStore.accessToken && Dependencies.dataStore.refreshToken) {
      this.setState({
        isAuthorized: true,
      })
    } else {
      this.setState({
        isAuthorized: false,
      })
    }
  }

  loginViewModelHasAuthorized(roles: UserRole[]) {
    if (isUserHaveRequiredRoles(roles) === false) {
      notification.error({ message: 'У вас нет требуемой роли для доступа к панели администратора' })
      this.useCaseFactory.createLogoutUseCase().logout()

      setTimeout(() => {
        if (window.location.pathname !== '/') {
          window.location.href = LOGOUT_ROUTE
        }
      }, 2000)

      return
    }

    this.setState({
      isAuthorized: true,
    })
    window.location.href = BOOKING_APPLICATIONS_ROUTE
  }

  loginFinishViewModelHasAuthorized() {
    this.setState({
      isAuthorized: true,
    })
    window.location.href = BOOKING_APPLICATIONS_ROUTE
  }

  loginFinishViewModelHasLogout() {
    this.setState({
      isAuthorized: false,
    })
    window.location.href = '/'
  }

  private makeNonAuthorizedRoutes = () => {
    let loginViewModel = new LoginViewModel(this.useCaseFactory.createLoginUseCase())
    loginViewModel.delegate = this

    let loginFinishViewModel = new LoginFinishViewModel(this.useCaseFactory.createLoginUseCase())
    loginFinishViewModel.delegate = this

    let logoutViewModel = new LogoutViewModel(this.useCaseFactory.createLogoutUseCase())
    logoutViewModel.delegate = this

    return (
      <Routes>
        <Route
          path={LOGIN_FINISH_ROUTE}
          element={<LoginFinish viewModel={loginFinishViewModel} />}
        />
        <Route
          path={ANONYMOUS_DEFAULT_ROUTE}
          element={<Login viewModel={loginViewModel} />}
        />
        <Route
          path={LOGOUT_ROUTE}
          element={<Logout viewModel={logoutViewModel} />}
        />
      </Routes>
    )
  }

  private makeAuthorizedRoutes = () => {
    let logoutViewModel = new LogoutViewModel(this.useCaseFactory.createLogoutUseCase())
    logoutViewModel.delegate = this

    return (
      <MainLayout>
        <Routes>
          <Route
            path={BOOKING_APPLICATIONS_ROUTE}
            element={
              <BookingApplicationsViewModelProvider>
                <BookingApplicationsView />
              </BookingApplicationsViewModelProvider>
            }
          />
          <Route
            path={BOOKING_AUDIENCE_GROUPS_ROUTE}
            element={
              <GroupsViewModelProvider>
                <AudienceGroupsView />
              </GroupsViewModelProvider>
            }
          />
          <Route
            path={BOOKING_ROLES_ROUTE}
            element={
              <RolesViewModelProvider>
                <RolesView />
              </RolesViewModelProvider>
            }
          />
          <Route
            path={USERS_ROUTE}
            element={
              <UsersViewModelProvider>
                <UsersView />
              </UsersViewModelProvider>
            }
          />

          <Route
            path={SETTINGS_ROUTE}
            element={
              <SettingsViewModelProvider>
                <SettingsView />
              </SettingsViewModelProvider>
            }
          />

          <Route
            path={LOGOUT_ROUTE}
            element={<Logout viewModel={logoutViewModel} />}
          />
          <Route
            path={ANONYMOUS_DEFAULT_ROUTE}
            element={<Navigate to={BOOKING_APPLICATIONS_ROUTE} />}
          />
        </Routes>
      </MainLayout>
    )
  }

  render() {
    return (
      <BrowserRouter>
        {this.state.isAuthorized ? this.makeAuthorizedRoutes() : this.makeNonAuthorizedRoutes()}
      </BrowserRouter>
    )
  }
}
