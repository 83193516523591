import { UserProfile, UserRole } from 'domain/common'
import { StaffInfo } from 'domain/users'

export const getSelectOptions = (staff: StaffInfo[], currentUser: UserProfile | undefined) => {
  const { accountId, lastName, firstName, secondName } = currentUser?.tsuAccountProfile || {}

  const userRolesOptions = [
    {
      value: UserRole.admin,
    },
    {
      value: UserRole.cacheRefresher,
    },
  ]

  const staffSelectOptions = [
    {
      value: accountId,
      label: `${lastName} ${firstName} ${secondName}`,
    },
    ...staff.map(({ name, accountId }) => {
      return {
        value: accountId,
        label: name,
      }
    }),
  ]

  return { userRolesOptions, staffSelectOptions }
}
