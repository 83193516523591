import React from 'react'
import { Layout, Spin } from 'antd'
import LogoutViewModel from '../view-models/LogoutViewModel'

interface LoginFinishProps {
  viewModel: LogoutViewModel
}

export default class Logout extends React.Component<LoginFinishProps> {
  private viewModel: LogoutViewModel

  constructor(props: LoginFinishProps) {
    super(props)
    this.viewModel = this.props.viewModel
  }

  componentDidMount() {
    this.viewModel.logout()
  }

  render() {
    return (
      <Layout>
        <Spin />
      </Layout>
    )
  }
}
