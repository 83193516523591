import { PropsWithChildren, createContext } from 'react'
import { UseCaseFactory } from 'shared'
import { useRolesViewModel } from './RolesViewModel'

export const RolesViewModelContext = createContext<ReturnType<typeof useRolesViewModel> | undefined>(undefined)

export const RolesViewModelProvider = ({ children }: PropsWithChildren) => {
  const useCaseFactory = new UseCaseFactory()
  let rolesViewModel = useRolesViewModel(useCaseFactory.createBookingRoleUseCase())

  return <RolesViewModelContext.Provider value={rolesViewModel}>{children}</RolesViewModelContext.Provider>
}
