import { Button, Card, Form, Select, Typography } from 'antd'
import { UserRole } from 'domain/common'

import styles from './index.module.scss'
import { getSelectOptions } from './common/useSelectOptions'
import { useSettingsViewModel } from 'presentation/scenes/settings/view-models'

type FormType = {
  tsuAccountId: string
  role: UserRole
}
export const AddUserRoleToAccountForm = () => {
  const {
    currentUser,
    staff,
    actions: { addRole },
  } = useSettingsViewModel()

  const { staffSelectOptions, userRolesOptions } = getSelectOptions(staff, currentUser)

  return (
    <Card
      className={styles['add-role-to-user-form']}
      data-cy='roleToAccount-form'
    >
      <Typography.Title level={2}>Добавить сотруднику роль</Typography.Title>
      <Form<FormType>
        style={{ maxWidth: 600 }}
        labelCol={{ span: 4 }}
        disabled={!currentUser?.roles.includes(UserRole.admin)}
        onFinish={({ tsuAccountId, role }) => {
          addRole(tsuAccountId, role)
        }}
      >
        <Form.Item<FormType>
          label='Сотрудник'
          name='tsuAccountId'
          rules={[{ required: true, message: 'Выберите сотрудника' }]}
        >
          <Select
            showSearch
            options={staffSelectOptions}
            filterOption={(input, option) =>
              (option?.label ?? '').trim().toLowerCase().includes(input.trim().toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item<FormType>
          label='Роль'
          name='role'
          rules={[{ required: true, message: 'Выберите роль' }]}
        >
          <Select options={userRolesOptions} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button
            type='primary'
            htmlType='submit'
          >
            Добавить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}
