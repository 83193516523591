import { Descriptions } from 'antd'
import { BookingApplicationStatus } from 'domain/booking'
import moment from 'moment'
import { useBookingApplicationViewModel } from 'presentation/scenes/booking/applications/view-models'
import React from 'react'

const fullDateFormat = 'D MMMM YYYY'

export const ApplicationInfoBody = () => {
  const { selectedApplication } = useBookingApplicationViewModel()

  if (!selectedApplication) return null

  const { date, participantCount, lessons, audience, additionalFields, status, requiredBookingRoles, approvedByRoles } =
    selectedApplication

  const formattedDate = moment(date).format(fullDateFormat)

  const LessonsLayout = () => {
    return (
      <>
        {lessons.map(lesson => {
          let startTime = moment(lesson.start * 1000).format('HH:mm')
          let endTime = moment(lesson.end * 1000).format('HH:mm')
          return (
            <>
              {`${lesson.lessonNumber}-я пара (${startTime}–${endTime})`}
              <br />
            </>
          )
        })}
      </>
    )
  }

  const RequiredBookingRolesLayout = () => {
    if (status !== BookingApplicationStatus.requireApprove) return null

    return (
      <Descriptions.Item label='Требуется согласование'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {requiredBookingRoles
            .filter(req => !approvedByRoles.some(approved => approved.id === req.id))
            .map(role => {
              return <div>{role.name}</div>
            })}
        </div>
      </Descriptions.Item>
    )
  }

  return (
    <Descriptions
      layout='vertical'
      labelStyle={{ fontWeight: 'bold' }}
    >
      <Descriptions.Item label='Дата'>{formattedDate}</Descriptions.Item>
      <Descriptions.Item label='Кол-во участников'>{participantCount}</Descriptions.Item>
      <Descriptions.Item label='Пары'>
        <LessonsLayout />
      </Descriptions.Item>

      <Descriptions.Item
        label='Аудитория'
        span={3}
      >
        {`${audience.name} ${audience.building ? `(${audience.building.name})` : null}`}
      </Descriptions.Item>

      {additionalFields.map(field => {
        return (
          <Descriptions.Item
            label={field.field.title}
            span={3}
          >
            {field.value}
          </Descriptions.Item>
        )
      })}

      <RequiredBookingRolesLayout />
    </Descriptions>
  )
}
