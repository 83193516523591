import React, { FC, PropsWithChildren, useState } from 'react'
import { Layout, Typography } from 'antd'
import { ReactComponent as TsuLogo } from 'shared/lib/assets/logos/tsu_logo_small.svg'
import SideMenu from './side-menu/SideMenu'
import './main-layout.scss'

const { Content, Footer, Sider } = Layout
const { Title, Text } = Typography

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const [isCollapsed, setCollapse] = useState<boolean>(false)

  return (
    <Layout className='main-layout'>
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={setCollapse}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className={`logo ${isCollapsed ? 'collapsed' : ''}`}>
          <TsuLogo />
          <div className='title-wrapper'>
            <Title level={5}>TSU.InTime</Title>
            <Text>Администрирование</Text>
          </div>
        </div>
        <SideMenu />
      </Sider>

      <Layout style={{ marginLeft: isCollapsed ? 100 : 200 }}>
        <Content>{children}</Content>
        <Footer>Ant Design ©2018 Created by Ant UED</Footer>
      </Layout>
    </Layout>
  )
}
