import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, FormInstance, Input, Select, Space } from 'antd'
import { BookingAudienceGroup } from 'domain/booking'
import { useGroupsViewModel } from 'presentation/scenes/booking/audienceGroups/view-models'
import React from 'react'

import './index.scss'
interface AdditionalFieldsFormItemProps {
  groupForm: FormInstance<BookingAudienceGroup>
}
export const AdditionalFieldsFormItem = ({ groupForm }: AdditionalFieldsFormItemProps) => {
  const { state } = useGroupsViewModel()

  return (
    <Form.List name='bookingFormAdditionalFields'>
      {(fields, { add, remove }) => {
        return (
          <div className='additional-fields-list'>
            {fields.map(({ key, name }, index) => (
              <div
                className='additional-field-wrapper'
                key={key}
                data-cy='additionalField-form'
              >
                <Space
                  align='start'
                  style={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <Space>
                    <Form.Item
                      name={[name, 'title']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Input placeholder='название поля' />
                    </Form.Item>

                    <Form.Item
                      name={[name, 'templateId']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Input placeholder='название переменной' />
                    </Form.Item>

                    <Form.Item
                      name={[name, 'type']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Select
                        className='form-items-row-type-selector'
                        placeholder={'тип данных'}
                        options={state.formFieldTypes.map(type => {
                          return { value: type, label: type, 'data-cy': `additionalType-item-${type}` }
                        })}
                      />
                    </Form.Item>
                  </Space>
                  <Button
                    danger
                    icon={<CloseCircleOutlined />}
                    onClick={() => remove(index)}
                  />
                </Space>

                <Form.Item
                  name={[name, 'isOptional']}
                  initialValue={false}
                  valuePropName='checked'
                >
                  <Checkbox value={false}>Поле опционально для заполнения?</Checkbox>
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <div>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  data-cy='newAdditionalField-btn'
                >
                  Добавить поле
                </Button>
              </div>
            </Form.Item>
          </div>
        )
      }}
    </Form.List>
  )
}
