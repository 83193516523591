import { BookingAudienceGroupUseCase, BookingRoleUseCase, BookingAudienceGroup } from 'domain/booking'
import { useEffect, useReducer } from 'react'
import { AudienceGroupsViewState, reducer } from './reducer'
import { Notifications } from 'shared'
import { getUpdateGroupPromises } from './hooks/getUpdateGroupPromises'
import { useBookingGroups } from './hooks/useBookingGroups'

const initState: AudienceGroupsViewState = {
  bookingAudienceGroups: [],
  selectedGroup: undefined,
  buildings: [],
  audiences: [],
  bookingRoles: [],
  formFieldTypes: [],
  isLoading: false,
  bookingFormFieldsTypes: [],
  userProfile: undefined,
}

export const useGroupsViewModel = (
  audienceGroupsUseCase: BookingAudienceGroupUseCase,
  bookingRoleUseCase: BookingRoleUseCase
) => {
  const [state, setState] = useReducer(reducer, initState)

  const onDidStartRequest = () => {
    setState({
      isLoading: true,
    })
  }
  const onDidFinishRequest = () => {
    setState({
      isLoading: false,
    })
  }

  const onDidReceiveError = (error: Error) => {
    Notifications.handle(error)
    setState({
      isLoading: false,
    })
  }

  function initViewModel() {
    onDidStartRequest()
    Promise.all([
      audienceGroupsUseCase.getBuildings(),
      bookingRoleUseCase.getBookingRoles(),
      audienceGroupsUseCase.getBookingFormFieldsTypes(),
      audienceGroupsUseCase.getBookingGroups(),
      bookingRoleUseCase.getCurrentUser(),
    ])
      .then(response => {
        const buildings = response[0]
        const bookingRoles = response[1]
        const formFieldTypes = response[2]
        const bookingAudienceGroups = response[3]
        const userProfile = response[4]

        setState({ buildings, bookingRoles, formFieldTypes, bookingAudienceGroups, userProfile })
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  useEffect(() => {
    initViewModel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getAudiences(buildingId: string) {
    onDidStartRequest()
    audienceGroupsUseCase
      .getAudiences(buildingId)
      .then(audiences => {
        setState({ audiences })
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  async function createBookingAudienceGroup(audienceGroup: BookingAudienceGroup) {
    onDidStartRequest()

    try {
      const { bookingFormAdditionalFields, requiredRoles, audiences } = audienceGroup
      const newGroup = await audienceGroupsUseCase.createBookingAudienceGroup(audienceGroup)

      await Promise.allSettled([
        ...bookingFormAdditionalFields.map(additionalField => {
          return audienceGroupsUseCase.addFormFieldInGroup(newGroup.id, additionalField)
        }),
        ...requiredRoles.map(requiredRole => {
          return audienceGroupsUseCase.addRoleInGroup(newGroup.id, requiredRole.id)
        }),
        ...audiences.map(audience => {
          return audienceGroupsUseCase.addAudienceInGroup(newGroup.id, audience.id)
        }),
      ])

      const bookingAudienceGroups = await audienceGroupsUseCase.getBookingGroups()
      setState({ bookingAudienceGroups })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  async function updateBookingAudienceGroup(audienceGroup: BookingAudienceGroup) {
    const initialAudienceGroup = state.bookingAudienceGroups.find(val => val.id === audienceGroup.id)
    if (!initialAudienceGroup) return

    onDidStartRequest()

    const { promises } = getUpdateGroupPromises(audienceGroup, initialAudienceGroup, audienceGroupsUseCase)
    try {
      const result = await Promise.all(promises)
      if (result.length > 0) {
        const bookingAudienceGroups = await audienceGroupsUseCase.getBookingGroups()
        setState({ bookingAudienceGroups })
      }
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  async function deleteBookingAudienceGroup(groupId: string) {
    onDidStartRequest()

    try {
      await audienceGroupsUseCase.deleteBookingAudienceGroup(groupId)
      const bookingAudienceGroups = await audienceGroupsUseCase.getBookingGroups()
      setState({ bookingAudienceGroups, selectedGroup: undefined })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  async function createBookingRole(roleName: string) {
    onDidStartRequest()

    try {
      await bookingRoleUseCase.createBookingRole({ name: roleName, email: state.userProfile?.email })
      const bookingRoles = await bookingRoleUseCase.getBookingRoles()
      setState({ bookingRoles })
    } catch (error: any) {
      onDidReceiveError(error)
    } finally {
      onDidFinishRequest()
    }
  }

  const { groups, searchGroups } = useBookingGroups(state.bookingAudienceGroups)

  return {
    state: { ...state, bookingAudienceGroups: groups },
    setState,
    actions: {
      getAudiences,
      updateBookingAudienceGroup,
      deleteBookingAudienceGroup,
      createBookingAudienceGroup,
      createBookingRole,
      searchGroups,
    },
  }
}
