import { PropsWithChildren, createContext } from 'react'
import { UseCaseFactory } from 'shared'
import { useSettingsViewModel } from './SettingsViewModel'

export const SettingsViewModelContext = createContext<ReturnType<typeof useSettingsViewModel> | undefined>(undefined)

export const SettingsViewModelProvider = ({ children }: PropsWithChildren) => {
  const useCaseFactory = new UseCaseFactory()
  let settingsViewModel = useSettingsViewModel(
    useCaseFactory.createUsersUseCase(),
    useCaseFactory.createBookingRoleUseCase(),
    useCaseFactory.createCacheUseCase()
  )

  return <SettingsViewModelContext.Provider value={settingsViewModel}>{children}</SettingsViewModelContext.Provider>
}
