import Axios from 'axios'
import { NetworkConstants } from 'data/common'
import { DataStore } from 'data/data-store'
import { AuthTokenInfo } from 'domain/auth'
import { HTTPMethod } from './HTTPMethod'
import { AxiosCacheInstance, CacheRequestConfig, setupCache } from 'axios-cache-interceptor'

const axios = setupCache(
  Axios.create({
    baseURL: NetworkConstants.apiBaseURL,
  })
)

export class NetworkService {
  private instance: AxiosCacheInstance

  protected dataStore: DataStore

  constructor(dataStore: DataStore) {
    this.instance = axios
    this.dataStore = dataStore
    this.setupInterceptors()
  }

  request<T>(
    url: string,
    method: HTTPMethod,
    parameters: Object | null = null,
    requestConfig?: CacheRequestConfig
  ): Promise<T> {
    let config: CacheRequestConfig = {
      url: url,
      method: method,
      cache: {
        cacheTakeover: false,
      },
      ...requestConfig,
    }

    if (method === HTTPMethod.get) {
      config.params = parameters
    } else {
      config.data = parameters
    }

    return this.instance
      .request(config)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        let customError = this.createError(error.response)
        return Promise.reject(customError)
      })
  }

  private createError(response: any): Error {
    if (response.data.code === 'BookingFailure') {
      return new Error(response.data.message)
    } else {
      return new Error(response.data.code)
    }
  }

  private setupInterceptors() {
    this.setupRequestInterceptor()
    this.setupResponseInterceptor()
  }

  private setupRequestInterceptor() {
    this.instance.interceptors.request.use(
      config => {
        if (this.dataStore.accessToken) {
          config.headers['Authorization'] = `Bearer ${this.dataStore.accessToken}`
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  private setupResponseInterceptor() {
    let interceptor = this.instance.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const originalRequest = error.config
        if (error.response.status === 401 && error.config.url !== '/auth/login/email') {
          this.instance.interceptors.response.eject(interceptor)
          return this.refreshToken()
            .then(token => {
              this.dataStore.accessToken = token.accessToken
              this.dataStore.refreshToken = token.refreshToken
              return this.instance(originalRequest)
            })
            .catch(error => {
              this.clearTokens()
              return Promise.reject(error)
            })
            .finally(() => {
              this.setupResponseInterceptor()
            })
        }
        return Promise.reject(error)
      }
    )
  }

  private async refreshToken(): Promise<AuthTokenInfo> {
    return this.request<AuthTokenInfo>('/auth/refresh', HTTPMethod.post, {
      refreshToken: this.dataStore.refreshToken,
    })
  }

  private clearTokens() {
    this.dataStore.accessToken = null
    this.dataStore.refreshToken = null
    window.location.href = '/'
  }
}
