import { Card, Empty } from 'antd'

import { useBookingApplicationViewModel } from '../../../view-models/hook'
import { ApplicationInfoBody, ApplicationInfoHeader, ApplicationInfoFooter } from './components'

export const SelectedApplicationCard = () => {
  const { selectedApplication } = useBookingApplicationViewModel()

  return (
    <Card
      className='booking-applications-details'
      title={selectedApplication?.title}
    >
      {selectedApplication ? (
        <>
          <ApplicationInfoHeader />
          <ApplicationInfoBody />
          <ApplicationInfoFooter />
        </>
      ) : (
        <Empty />
      )}
    </Card>
  )
}
