import { useContext } from 'react'
import { UsersViewModelContext } from './provider'

export const useUsersViewModel = () => {
  const value = useContext(UsersViewModelContext)

  if (!value) {
    throw new Error('useUsersViewModel must be used within a UsersViewModelContext')
  }

  return value
}
