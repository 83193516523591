import { Button, Card, Spin, Typography } from 'antd'
import { AddUserRoleToAccountForm } from './components/AddUserRoleToAccountForm'
import { useSettingsViewModel } from '../view-models'
import { UserRole } from 'domain/common'
const { Title } = Typography
export const SettingsView = () => {
  const {
    isLoading,
    currentUser,
    actions: { updateCache },
  } = useSettingsViewModel()

  return (
    <>
      <Title>Настройки</Title>

      <Spin spinning={isLoading}>
        <AddUserRoleToAccountForm />

        <Card>
          <Title level={2}>Кэш</Title>

          <Button
            onClick={() => updateCache()}
            disabled={!currentUser?.roles.includes(UserRole.cacheRefresher)}
            data-cy='updateCache-btn'
          >
            Обновить кэш бекенда
          </Button>
        </Card>
      </Spin>
    </>
  )
}
