import { Audience } from 'domain/buildings'
import { useGroupsViewModel } from 'presentation/scenes/booking/audienceGroups/view-models'
import { useState, useEffect, useMemo, useCallback } from 'react'

const regularAudienceId = '00000000-0000-0000-0000-000000000000'
export const useAudiencesAndBuildings = () => {
  const {
    state: { audiences, buildings, bookingAudienceGroups, selectedGroup },
    actions: { getAudiences },
  } = useGroupsViewModel()

  const [filteredAudiences, setFilteredAudiences] = useState<Audience[]>([])

  const availableAudiences = useMemo(() => {
    const selectedAudiencesInOtherGroups = bookingAudienceGroups
      .filter(group => group.id !== regularAudienceId && group.id !== selectedGroup?.id)
      .flatMap(group => group.audiences)

    return audiences.filter(aud => !selectedAudiencesInOtherGroups.some(x => x.id === aud.id))
  }, [audiences, bookingAudienceGroups, selectedGroup?.id])

  useEffect(() => {
    if (audiences.length !== 0) {
      setFilteredAudiences(availableAudiences)
    }
  }, [audiences.length, availableAudiences])

  const searchAudiences = useCallback(
    (searchString: string) => {
      const filteredAudiences = availableAudiences.filter(role =>
        role.name.toLowerCase().includes(searchString.trim().toLocaleLowerCase())
      )
      setFilteredAudiences(filteredAudiences)
    },
    [availableAudiences]
  )

  const getAudiencesByBuildingId = (buildingId: string) => {
    getAudiences(buildingId)
  }

  return { audiences: filteredAudiences, searchAudiences, buildings, getAudiencesByBuildingId }
}
