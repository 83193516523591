import { Popover, Button } from 'antd'
import { BookingRole } from 'domain/booking'
import BookingRolesList from 'presentation/components/BookingRolesList'
import { useUsersViewModel } from 'presentation/scenes/users/view-models'
import React, { useState } from 'react'
import { addNewModeratorBtnDataCy, newModeratorPopoverKeys } from 'shared'
interface AddNewModeratorPopoverProps {
  selectedUserId?: string
}
export const AddNewModeratorPopover = ({ selectedUserId }: AddNewModeratorPopoverProps) => {
  const {
    bookingRoles,
    actions: { createBookingRole, addBookingAdmin },
  } = useUsersViewModel()

  function createBookingRoleHandler(value: string, e: any) {
    e.preventDefault()
    createBookingRole({ name: value })
  }

  const [selectedBookingRoles, setSelectedBookngRoles] = useState<BookingRole[]>([])
  function bookingRolesCheckboxChanged(e: any, role: BookingRole) {
    if (e.target.checked) {
      setSelectedBookngRoles([...selectedBookingRoles, role])
    } else {
      setSelectedBookngRoles(selectedBookingRoles.filter(val => val.id !== role.id))
    }
  }

  const [isAddNewAdminPopoverOpen, setIsAddNewAdminPopoverOpen] = useState<boolean>(false)

  return (
    <Popover
      trigger='click'
      placement='bottom'
      title='Выберите роли'
      open={isAddNewAdminPopoverOpen}
      onOpenChange={visible => {
        if (!selectedUserId) return
        setIsAddNewAdminPopoverOpen(visible)
      }}
      content={
        <div
          style={{ width: '700px' }}
          data-cy={newModeratorPopoverKeys.newModeratorPopoverDataCy}
        >
          <BookingRolesList
            selectedBookingRoles={selectedBookingRoles}
            bookingRoles={bookingRoles}
            createBookingRole={createBookingRoleHandler}
            bookingRolesCheckboxChanged={bookingRolesCheckboxChanged}
          />
          <Button
            type='primary'
            disabled={selectedBookingRoles.length === 0}
            style={{ marginTop: '16px' }}
            block
            data-cy={newModeratorPopoverKeys.createNewModeratorBtn}
            onClick={() => {
              addBookingAdmin(
                selectedBookingRoles.map(role => {
                  return role.id
                }),
                selectedUserId
              )
              setIsAddNewAdminPopoverOpen(false)
            }}
          >
            Дать выбранному сотруднику права модератора
          </Button>
        </div>
      }
    >
      <Button
        type='primary'
        disabled={!selectedUserId}
        data-cy={addNewModeratorBtnDataCy}
      >
        Добавить
      </Button>
    </Popover>
  )
}
