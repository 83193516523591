import { BookingRepository } from 'domain/booking'
import { AuthRepository } from 'domain/auth'
import { StaffRepository } from 'domain/users'
import { BuildingsRepository } from 'domain/buildings'
import { DataStore, LocalDataStore, AuthService, StaffService, BookingService, BuildingsService } from 'data'
import { CacheRepository } from 'domain/cache'
import { CacheService } from 'data/network-service/services/CacheService'

class DependenciesContainer {
  dataStore: DataStore
  authRepository: AuthRepository
  staffRepository: StaffRepository
  bookingRepository: BookingRepository
  buildingsRepository: BuildingsRepository
  cacheRepository: CacheRepository

  constructor() {
    this.dataStore = new LocalDataStore()
    this.authRepository = new AuthService(this.dataStore)
    this.staffRepository = new StaffService(this.dataStore)
    this.bookingRepository = new BookingService(this.dataStore)
    this.buildingsRepository = new BuildingsService(this.dataStore)
    this.cacheRepository = new CacheService(this.dataStore)
  }
}

export const Dependencies = new DependenciesContainer()
