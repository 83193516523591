import { BookingRole } from 'domain/booking'
import { UserProfile } from 'domain/common'
import { StaffInfo } from 'domain/users'
import { Action } from './actions'

export interface UsersViewState {
  staff: StaffInfo[]
  moderators: UserProfile[]
  isLoading: boolean
  bookingRoles: BookingRole[]
  currentUser?: UserProfile
}

export const reducer = (state: UsersViewState, { type, payload }: Action): UsersViewState => {
  switch (type) {
    case 'DID_START_REQUEST':
      return { ...state, isLoading: payload }
    case 'DID_FINISH_REQUEST':
      return { ...state, isLoading: payload }
    case 'DID_RECIEVE_ERROR':
      return { ...state, isLoading: payload }
    case 'LOAD_DATA': {
      return { ...state, ...payload }
    }
    case 'SET_BOOKING_ROLES': {
      return { ...state, ...payload }
    }
    case 'SET_MODERATORS': {
      return { ...state, ...payload }
    }
    default:
      return state
  }
}
