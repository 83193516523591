import { HTTPMethod } from '../HTTPMethod'
import { NetworkService } from '../NetworkService'

import { CacheRepository } from 'domain/cache'
import { EmptyResponse } from '../EmptyResponse'

export class CacheService extends NetworkService implements CacheRepository {
  updateCache(): Promise<EmptyResponse> {
    return this.request('/caches/update', HTTPMethod.post)
  }
}
