import { useContext } from 'react'
import { AudienceGroupsViewModelContext } from './provider'

export const useGroupsViewModel = () => {
  const value = useContext(AudienceGroupsViewModelContext)

  if (!value) {
    throw new Error('useGroupsViewModel must be used within a AudienceGroupsViewModelContext')
  }

  return value
}
