import { PropsWithChildren, createContext } from 'react'
import { UseCaseFactory } from 'shared'
import { useUsersViewModel } from './UsersViewModel'

export const UsersViewModelContext = createContext<ReturnType<typeof useUsersViewModel> | undefined>(undefined)

export const UsersViewModelProvider = ({ children }: PropsWithChildren) => {
  const useCaseFactory = new UseCaseFactory()
  let usersViewModel = useUsersViewModel(
    useCaseFactory.createUsersUseCase(),
    useCaseFactory.createBookingRoleUseCase(),
    useCaseFactory.createCacheUseCase()
  )

  return <UsersViewModelContext.Provider value={usersViewModel}>{children}</UsersViewModelContext.Provider>
}
