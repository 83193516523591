import { Avatar, Tag } from 'antd'
import React from 'react'
import { statusInfoRecord } from '../../../common'
import { useBookingApplicationViewModel } from 'presentation/scenes/booking/applications/view-models'

export const ApplicationInfoHeader = () => {
  const { selectedApplication } = useBookingApplicationViewModel()

  if (!selectedApplication) return null

  const {
    user: {
      email,
      tsuAccountProfile: { lastName, firstName, secondName, avatarUrl },
    },
    status,
  } = selectedApplication

  return (
    <div className='booking-applications-top-container'>
      <div className='user-info'>
        <Avatar
          size={48}
          className='avatar'
          src={avatarUrl}
        />
        <div className='user-description-container'>
          <span className='user-name'>{`${lastName} ${firstName} ${secondName}`}</span>
          <a href={`mailto://${email}`}>{email}</a>
        </div>
      </div>
      <Tag
        data-cy='tag'
        color={statusInfoRecord[status].color}
      >
        {statusInfoRecord[status].name}
      </Tag>
    </div>
  )
}
