import { BookingAudienceGroup, BookingAudienceGroupBody, BookingAudienceGroupUseCase } from 'domain/booking'

const getAddedAndDeletedObjects = <T extends { id: string }>(initialArray: T[], newArray: T[]) => {
  const added = newArray.filter(val => !initialArray.some(({ id }) => val.id === id))
  const deleted = initialArray.filter(val => !newArray.some(({ id }) => val.id === id))

  return { added, deleted }
}

export const getUpdateGroupPromises = (
  audienceGroup: BookingAudienceGroup,
  initialAudienceGroup: BookingAudienceGroup,
  audienceGroupsUseCase: BookingAudienceGroupUseCase
) => {
  const { added: addedRoles, deleted: deletedRoles } = getAddedAndDeletedObjects(
    initialAudienceGroup.requiredRoles,
    audienceGroup.requiredRoles
  )

  const { added: addedAudiences, deleted: deletedAudiences } = getAddedAndDeletedObjects(
    initialAudienceGroup.audiences,
    audienceGroup.audiences
  )

  const { added: addedFields, deleted: deletedFields } = getAddedAndDeletedObjects(
    initialAudienceGroup.bookingFormAdditionalFields,
    audienceGroup.bookingFormAdditionalFields
  )

  const changedFields = audienceGroup.bookingFormAdditionalFields
    .filter(val => !addedFields.some(({ id }) => val.id === id) && !deletedFields.some(({ id }) => val.id === id))
    .filter(val => {
      const initialAdditionalField = initialAudienceGroup.bookingFormAdditionalFields.find(({ id }) => val.id === id)
      return JSON.stringify(val) !== JSON.stringify(initialAdditionalField)
    })

  const audienceGroupBody: BookingAudienceGroupBody = {
    name: audienceGroup.name,
    isNotifyAboutNewBookingsRequested: audienceGroup.isNotifyAboutNewBookingsRequested,
    emailTemplate: audienceGroup.emailTemplate || null,
  }

  const initialAudienceGroupBody: BookingAudienceGroupBody = {
    name: initialAudienceGroup.name,
    isNotifyAboutNewBookingsRequested: initialAudienceGroup.isNotifyAboutNewBookingsRequested,
    emailTemplate: initialAudienceGroup.emailTemplate || null,
  }

  const isShouldUpdateGroupBody = JSON.stringify(audienceGroupBody) !== JSON.stringify(initialAudienceGroupBody)

  const promises = [
    ...addedRoles.map(role => audienceGroupsUseCase.addRoleInGroup(audienceGroup.id, role.id)),
    ...deletedRoles.map(role => audienceGroupsUseCase.deleteRoleFromGroup(audienceGroup.id, role.id)),
    ...addedAudiences.map(audienece => audienceGroupsUseCase.addAudienceInGroup(audienceGroup.id, audienece.id)),
    ...deletedAudiences.map(audienece => audienceGroupsUseCase.deleteAudienceFromGroup(audienceGroup.id, audienece.id)),
    ...addedFields.map(field => audienceGroupsUseCase.addFormFieldInGroup(audienceGroup.id, field)),
    ...deletedFields.map(field => audienceGroupsUseCase.deleteFormFieldFromGroup(audienceGroup.id, field.id)),
    ...changedFields.map(field => audienceGroupsUseCase.updateFormFieldInGroup(audienceGroup.id, field.id, field)),
  ]

  if (isShouldUpdateGroupBody) {
    promises.push(audienceGroupsUseCase.updateBookingAudienceGroup(audienceGroup.id, audienceGroupBody))
  }

  return { promises }
}
