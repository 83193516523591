import { BookingRoleUseCase } from 'domain/booking'
import { UsersUseCase } from 'domain/users'
import { useEffect, useReducer } from 'react'
import { Notifications } from 'shared'
import { SettingsViewState, reducer } from './reducer'
import { UserRole } from 'domain/common'
import { CacheUseCase } from 'domain/cache/interactors/CacheUseCase'
import { notification } from 'antd'

const initState: SettingsViewState = {
  staff: [],
  currentUser: undefined,
  isLoading: false,
}

export const useSettingsViewModel = (
  usersUseCase: UsersUseCase,
  bookingRoleUseCase: BookingRoleUseCase,
  cacheUseCase: CacheUseCase
) => {
  const [state, dispatch] = useReducer(reducer, initState)

  const onDidStartRequest: () => void = () => {
    dispatch({ isLoading: true })
  }
  const onDidFinishRequest: () => void = () => {
    dispatch({ isLoading: false })
  }
  const onDidReceiveError: (error: Error) => void = error => {
    Notifications.handle(error)
    dispatch({ isLoading: false })
  }

  const loadData = () => {
    onDidStartRequest()
    Promise.all([usersUseCase.getStaff(), bookingRoleUseCase.getCurrentUser()])
      .then(response => {
        const staff = response[0]
        const currentUser = response[1]
        dispatch({ staff, currentUser })
      })
      .catch(error => {
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addRole = (tsuAccountId: string, role: UserRole) => {
    onDidStartRequest()
    usersUseCase
      .addRole(tsuAccountId, role)
      .then(() => {
        notification.success({ message: 'Роль успешно добавлена' })
        if (tsuAccountId === state.currentUser?.tsuAccountProfile.accountId) {
          bookingRoleUseCase.getCurrentUser().then(val => {
            window.localStorage.setItem('userProfile', JSON.stringify(val))
            dispatch({ ...state, currentUser: val })
          })
        }
      })
      .catch(error => {
        notification.error({ message: 'Не удалось добавить роль' })
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }

  const updateCache = () => {
    onDidStartRequest()
    cacheUseCase
      .updateCache()

      .then(() => {
        notification.success({ message: 'Кэш успешно обновлён' })
      })
      .catch(error => {
        notification.error({ message: 'Не удалось обновить кэш' })
        onDidReceiveError(error)
      })
      .finally(() => {
        onDidFinishRequest()
      })
  }
  return {
    ...state,
    actions: {
      addRole,
      updateCache,
    },
  }
}
