import { Popover, Button, Form } from 'antd'
import { BookingRole, BookingRoleBody } from 'domain/booking'
import React, { useState } from 'react'
import { useRolesViewModel } from '../../../view-models'

interface SelectedRoleActionsProps {
  selectedRole: BookingRole
  initialSelectedRole: BookingRole | undefined
  setSelectedRole: React.Dispatch<
    React.SetStateAction<
      | {
          selectedRole: BookingRole
          initialSelectedRole: BookingRole
        }
      | undefined
    >
  >
}

export const SelectedRoleActions = ({
  selectedRole,
  initialSelectedRole,
  setSelectedRole,
}: SelectedRoleActionsProps) => {
  const {
    actions: { updateBookingRole, deleteBookingRole },
  } = useRolesViewModel()

  const [isDeletePopoverVisible, setisDeletePopoverVisible] = useState<boolean>(false)

  const deleteBookingRoleHandler = (bookingRoleId: string) => {
    deleteBookingRole(bookingRoleId)
    setSelectedRole(undefined)
  }

  const updateBookingRoleHanlder = () => {
    if (!selectedRole) return

    const newBookingRole: BookingRoleBody = selectedRole
    updateBookingRole(selectedRole!.id, newBookingRole)
    setSelectedRole(undefined)
  }

  const isUpdateButtonDisabled: boolean =
    selectedRole.name === '' ||
    selectedRole.email === '' ||
    JSON.stringify(selectedRole) === JSON.stringify(initialSelectedRole)

  return (
    <div className='buttons-wrapper'>
      <Popover
        placement='left'
        trigger='click'
        title='Удалить роль?'
        open={isDeletePopoverVisible}
        onOpenChange={setisDeletePopoverVisible}
        content={
          <div style={{ display: 'flex', gap: '16px' }}>
            <Button
              danger
              onClick={() => {
                deleteBookingRoleHandler(selectedRole!.id)
                setisDeletePopoverVisible(false)
              }}
            >
              Да
            </Button>

            <Button
              type='primary'
              onClick={() => setisDeletePopoverVisible(false)}
            >
              Нет
            </Button>
          </div>
        }
      >
        <Button danger>Удалить роль</Button>
      </Popover>
      <Form.Item>
        <div>
          <Button
            type='primary'
            htmlType='submit'
            disabled={isUpdateButtonDisabled}
            onClick={() => updateBookingRoleHanlder()}
          >
            Применить изменения
          </Button>
        </div>
      </Form.Item>
    </div>
  )
}
