import { BookingRole } from 'domain/booking'
import { useGroupsViewModel } from 'presentation/scenes/booking/audienceGroups/view-models'
import { useState, useEffect } from 'react'

export const useBookingRoles = () => {
  const {
    state: { bookingRoles },
  } = useGroupsViewModel()

  const [filteredRoles, setFilteredRoles] = useState<BookingRole[]>([])

  useEffect(() => {
    if (bookingRoles.length !== 0) {
      setFilteredRoles(bookingRoles)
    }
  }, [bookingRoles])

  const searchRoles = (searchString: string) => {
    const filteredRoles = bookingRoles.filter(role =>
      role.name.toLowerCase().includes(searchString.trim().toLocaleLowerCase())
    )
    setFilteredRoles(filteredRoles)
  }

  return { bookingRoles: filteredRoles, searchRoles }
}
