import { BookingAudienceGroup } from 'domain/booking'
import { useState, useEffect, useCallback } from 'react'

export const useBookingGroups = (bookingAudienceGroups: BookingAudienceGroup[]) => {
  const [filteredGroups, setFilteredGroups] = useState<BookingAudienceGroup[]>([])

  useEffect(() => {
    if (bookingAudienceGroups.length !== 0) {
      setFilteredGroups(bookingAudienceGroups)
    }
  }, [bookingAudienceGroups])

  const searchGroups = useCallback(
    (searchString: string) => {
      const filteredGroups = bookingAudienceGroups.filter(group =>
        group.name.toLowerCase().includes(searchString.trim().toLocaleLowerCase())
      )
      setFilteredGroups(filteredGroups)
    },
    [bookingAudienceGroups]
  )

  return { groups: filteredGroups, searchGroups }
}
