import { Input, Button } from 'antd'
import React from 'react'
import { useGroupsViewModel } from '../../../view-models'

export const ViewHeader = () => {
  const {
    setState,
    actions: { searchGroups },
  } = useGroupsViewModel()

  return (
    <div className='header-wrapper'>
      <Input.Search
        className='search-input'
        placeholder='Название'
        onSearch={searchGroups}
        enterButton
        data-cy='audienceGroups-searchInput'
      />
      <Button
        type={'primary'}
        onClick={() => setState({ selectedGroup: undefined })}
      >
        Добавить группу
      </Button>
    </div>
  )
}
