import { Button, Checkbox, Col, Form, FormInstance, Input, List, Row, Select, Space, Typography } from 'antd'
import { BookingAudienceGroup } from 'domain/booking'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import './index.scss'
import { useAudiencesAndBuildings } from './hooks/useAudiences'
import { Audience } from 'domain/buildings'

const { Text } = Typography
interface AudiencesListProps {
  groupForm: FormInstance<BookingAudienceGroup>
}
export const AudiencesList = ({ groupForm }: AudiencesListProps) => {
  const selectedAudiences = Form.useWatch('audiences', groupForm)

  const isAudienceSelected = (audienceId: string) => {
    return selectedAudiences.some(audience => audience.id === audienceId)
  }

  const switchAudience = (audience: Audience) => {
    if (isAudienceSelected(audience.id)) {
      groupForm.setFieldValue(
        'audiences',
        selectedAudiences.filter(selectedaudience => selectedaudience.id !== audience.id)
      )
    } else {
      groupForm.setFieldValue('audiences', [...selectedAudiences, audience])
    }
  }

  const { audiences, searchAudiences, buildings, getAudiencesByBuildingId } = useAudiencesAndBuildings()

  return (
    <Row gutter={16}>
      <Col
        xs={24}
        md={10}
      >
        <Form.Item<BookingAudienceGroup>
          name='audiences'
          valuePropName='dataSource'
        >
          <List<Audience>
            size='small'
            rootClassName='selected-audiences-list'
            header={<Text>Список аудиторий: </Text>}
            renderItem={audience => {
              return (
                <List.Item data-cy={`audiencesList-item-${audience.id}`}>
                  <Text>{audience.name}</Text>

                  <Button
                    type='text'
                    danger
                    size='small'
                    icon={<CloseCircleOutlined />}
                    onClick={() => switchAudience(audience)}
                  />
                </List.Item>
              )
            }}
          />
        </Form.Item>
      </Col>
      <Col
        xs={24}
        md={14}
      >
        <Space
          direction='vertical'
          style={{ display: 'flex' }}
        >
          <Select
            showSearch
            placeholder='Корпус'
            style={{ width: '100%' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.trim().toLowerCase())}
            onChange={(value: string) => getAudiencesByBuildingId(value)}
            options={buildings.map(({ id, name }) => {
              return { value: id, label: name }
            })}
            data-cy='corpusSelect'
          />

          <Input
            placeholder='Поиск'
            prefix={<SearchOutlined />}
            onChange={e => searchAudiences(e.currentTarget.value)}
          />
          <List
            size='small'
            rootClassName='all-audiences-list'
            dataSource={audiences}
            renderItem={audience => {
              return (
                <List.Item data-cy={`allAudiencesList-item-${audience.id}`}>
                  <Checkbox
                    checked={isAudienceSelected(audience.id)}
                    onChange={() => switchAudience(audience)}
                    className='checkbox-label'
                  >
                    <Text>{audience.name}</Text>
                  </Checkbox>
                </List.Item>
              )
            }}
          />
        </Space>
      </Col>
    </Row>
  )
}
