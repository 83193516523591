import { Card, Table, Typography } from 'antd'
import { BookingRole } from 'domain/booking'
import React from 'react'
import { useRolesViewModel } from '../../../view-models'
const { Paragraph } = Typography

interface RolesListProps {
  setselectedRole: React.Dispatch<
    React.SetStateAction<
      | {
          selectedRole: BookingRole
          initialSelectedRole: BookingRole
        }
      | undefined
    >
  >
}
export const RolesList = ({ setselectedRole }: RolesListProps) => {
  const { filteredBookingRoles } = useRolesViewModel()

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Table
        data-cy='table-role'
        showHeader={false}
        dataSource={filteredBookingRoles}
        rowKey={record => record.id}
        pagination={{
          size: 'small',
        }}
      >
        <Table.Column
          dataIndex='title'
          render={(text, record: BookingRole) => {
            return (
              <div
                className='audience-groups-list-content'
                id={record.id}
                onClick={() => {
                  setselectedRole({
                    selectedRole: record,
                    initialSelectedRole: record,
                  })
                }}
              >
                <Paragraph
                  ellipsis={{
                    rows: 1,
                  }}
                  className='title'
                >
                  {record.name}
                </Paragraph>
              </div>
            )
          }}
        />
      </Table>
    </Card>
  )
}
