import { AuthRepository } from '../repository'

export class LogoutUseCase {
  private authRepository: AuthRepository

  constructor(authRepository: AuthRepository) {
    this.authRepository = authRepository
  }

  logout() {
    this.authRepository.logout()
  }
}
