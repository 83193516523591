import { BookingRole } from 'domain/booking'
import { Action } from './actions'

export interface RolesViewState {
  bookingRoles: BookingRole[]
  filteredBookingRoles: BookingRole[]
  isLoading: boolean
  userEmail?: string
}

export const reducer = (state: RolesViewState, { type, payload }: Action): RolesViewState => {
  switch (type) {
    case 'DID_START_REQUEST':
      return { ...state, isLoading: payload }
    case 'DID_FINISH_REQUEST':
      return { ...state, isLoading: payload }
    case 'DID_RECIEVE_ERROR':
      return { ...state, isLoading: payload }
    case 'LOAD_DATA': {
      const { userEmail, bookingRoles } = payload
      return { ...state, userEmail, bookingRoles }
    }
    case 'SET_BOOKING_ROLES': {
      const { bookingRoles } = payload
      return { ...state, bookingRoles, filteredBookingRoles: bookingRoles }
    }
    case 'SET_FILTERED_BOOKING_ROLES': {
      const { filteredBookingRoles } = payload
      return { ...state, filteredBookingRoles }
    }

    default:
      return state
  }
}
