import { Form, Input, Button, FormInstance } from 'antd'
import { BookingRoleBody } from 'domain/booking'
import { useRolesViewModel } from 'presentation/scenes/booking/roles/view-models'
import React, { useRef } from 'react'

export const NewBookingRoleForm = () => {
  const {
    userEmail,
    actions: { createBookingRole },
  } = useRolesViewModel()

  const newRoleForm = useRef<FormInstance>(null)

  return (
    <Form<BookingRoleBody>
      ref={newRoleForm}
      onFinish={values => {
        createBookingRole(values)
        newRoleForm.current?.resetFields()
      }}
    >
      <Form.Item<BookingRoleBody>
        name='name'
        rules={[{ required: true, message: '' }]}
      >
        <Input placeholder='Название роли' />
      </Form.Item>

      <Form.Item<BookingRoleBody>
        name='email'
        rules={[{ required: true, message: '' }]}
        initialValue={userEmail}
      >
        <Input placeholder='Эл. почта' />
      </Form.Item>

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          block
        >
          Добавить роль
        </Button>
      </Form.Item>
    </Form>
  )
}
