import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import MainRouter from './presentation/routing/MainRouter'

function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <MainRouter />
    </ConfigProvider>
  )
}

export default App
