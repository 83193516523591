import { LoginUseCase } from 'domain/auth'
import { UserRole } from 'domain/common'

export interface LoginViewModelDelegate {
  loginViewModelHasAuthorized(roles: UserRole[]): void
}

export default class LoginViewModel {
  delegate?: LoginViewModelDelegate

  onDidStartRequest: () => void = () => {}
  onDidFinishRequest: () => void = () => {}
  onDidReceiveError: (error: Error) => void = () => {}

  private loginUseCase: LoginUseCase

  constructor(loginUseCase: LoginUseCase) {
    this.loginUseCase = loginUseCase
  }

  loginWithEmail(email: string, password: string) {
    this.onDidStartRequest()
    this.loginUseCase
      .loginWithEmail(email, password)
      .then(({ profile: { roles } }) => {
        this.delegate?.loginViewModelHasAuthorized(roles)
      })
      .catch(error => {
        this.onDidReceiveError(error)
      })
      .finally(() => {
        this.onDidFinishRequest()
      })
  }
}
