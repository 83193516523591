import { BookingApplication, BookingApplicationStatus, BookingRepository } from 'domain/booking'
import { UserProfile } from 'domain/common'
import { Pagination } from 'shared/lib/utils/Pagination'

export class BookingUseCase {
  private bookingRepository: BookingRepository

  constructor(bookingRepository: BookingRepository) {
    this.bookingRepository = bookingRepository
  }

  getBookingApplications(data: {
    page?: number
    limit?: number
    sortOrder?: 'DEFAULT' | 'ASC' | 'DESC'
    title?: string
    statuses?: Array<BookingApplicationStatus>
  }): Promise<Pagination<BookingApplication[]>> {
    return this.bookingRepository.getBookingApplications(data)
  }

  approveBooking(bookingID: string): Promise<BookingApplication> {
    return this.bookingRepository.approveBooking(bookingID)
  }

  rejectBooking(bookingID: string, reason: string): Promise<BookingApplication> {
    return this.bookingRepository.rejectBooking(bookingID, reason)
  }

  getCurrentUser(): Promise<UserProfile> {
    return this.bookingRepository.getCurrentUser()
  }
}
