import { LoginUseCase } from 'domain/auth'
import { UserRole } from 'domain/common'

enum Constants {
  tokenQueryParameter = 'token',
}

export interface LoginFinishViewModelDelegate {
  loginViewModelHasAuthorized(roles: UserRole[]): void
}

export default class LoginFinishViewModel {
  delegate?: LoginFinishViewModelDelegate

  onDidReceiveError: (error: Error) => void = () => {}

  private loginUseCase: LoginUseCase

  constructor(loginUseCase: LoginUseCase) {
    this.loginUseCase = loginUseCase
  }

  finishAuthorization() {
    let query = new URLSearchParams(window.location.search)
    let token = query.get(Constants.tokenQueryParameter)

    if (token) {
      this.loginUseCase
        .loginWithTsuAccount(token)
        .then(({ profile: { roles } }) => {
          this.delegate?.loginViewModelHasAuthorized(roles)
        })
        .catch(error => {
          this.onDidReceiveError(error)
        })
    }
  }
}
