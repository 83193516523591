import { PropsWithChildren, createContext } from 'react'
import { useBookingApplicationsViewModel } from './BookingApplicationsViewModel'
import { UseCaseFactory } from 'shared'

export const BookingApplicationsViewModelContext = createContext<
  ReturnType<typeof useBookingApplicationsViewModel> | undefined
>(undefined)

export const BookingApplicationsViewModelProvider = ({ children }: PropsWithChildren) => {
  const useCaseFactory = new UseCaseFactory()
  let bookingApplicationsViewModel = useBookingApplicationsViewModel(useCaseFactory.createBookingUseCase())

  return (
    <BookingApplicationsViewModelContext.Provider value={bookingApplicationsViewModel}>
      {children}
    </BookingApplicationsViewModelContext.Provider>
  )
}
